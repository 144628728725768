import {useEffect, useRef, useState} from 'react';
import {IPeriodOption} from 'components/spreadsheet/spreadsheet-adapter';
import {IApiReturnBasic, INodeInfo} from 'api/data-types';
import useApi from 'api/useApi';

export type ILatestNode = {
  hierarchy?: string[];
  name?: string;
  database?: string;
  value?: string[][] | number[][];
  updateTime?: number;
  key: string; // 'mode_fcc-FCC-Feed-Temperature'
};
export type ILatestNodeResult = {
  [key: string]: ILatestNode[];
};

type ISubscribeInfo = {
  targetList: INodeInfo[];
  activate: boolean;
};

type ILatestNodeSubscribe = {
  [key: string]: ISubscribeInfo;
};

export type ILatestNodeHandlerReturn = {
  subscribe: ILatestNodeSubscribe;
  latestResult: ILatestNodeResult;
  disconnectSubscribe(id: string): void;
  renewSubscribe(id: string, nodeInfos: INodeInfo[], activate: boolean): number;
  changeDuration(newDuration: number): void;
  onLoadLatestNodeResult(tagResult: ILatestNodeResult): void;
};

type IReturnGetNodeData = {
  database: string;
  node: string[];
  data: IReturnGetNodeDataData[];
};

type IReturnGetNodeDataData = {
  feature: string;
  records: number[][] | string[][];
};

function useLatestNodeHandler(option: IPeriodOption): ILatestNodeHandlerReturn {
  const [subscribe, setSubscribe] = useState<ILatestNodeSubscribe>({});
  const [result, setResult] = useState<ILatestNodeResult>({});
  const [duration, setDuration] = useState(15 * 1000);
  const resultRef = useRef(null);
  const subscribeRef = useRef(null);
  subscribeRef.current = subscribe;
  resultRef.current = result;
  const api = useApi();

  useEffect(() => {
    const fnc = async () => {
      const currentSubscribe = {...subscribeRef.current} as ISubscribeInfo;
      const idArr = Object.keys(currentSubscribe);
      let node_infos = [];
      for (let i = 0; i < idArr.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        if (subScribeInfo?.activate) {
          const t = subScribeInfo?.targetList.map((item) => ({
            database: item.database,
            node: [...item.hierarchy, item.name],
            // latest_count: 1
            [option.type]: option[option.type]
          }));
          node_infos = node_infos.concat(t);
        }
      }
      // console.log(node_infos);
      if (node_infos?.length === 0) {
        return;
      }
      const res = await api.post<IApiReturnBasic>('/node_manage/get_hierarchy_node_data_list', {node_infos});
      const nodeDataList = res.data as IReturnGetNodeData[];
      const now = Date.now();
      const refined = nodeDataList.map((item) => {
        const db = item.database;
        return item.data.map((d) => {
          const stringArray = d.feature.split(', ');
          return {
            key: [db, ...stringArray].join(', '),
            value: d.records,
            updateTime: now
          };
        }) as unknown[];
      });

      const flat = refined.flat(1) as ILatestNode[];
      let newResult = {...resultRef?.current};
      const idArr_ = Object.keys(newResult);
      for (let i = 0; i < idArr_.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        const isActivate = subScribeInfo?.activate;

        const prevResult = newResult[uniqueId] || ([] as ILatestNode[]);
        const nextResult = prevResult.map(function (prevRes) {
          const nextRes = flat.find((n) => n.key === prevRes.key);
          if (nextRes && isActivate) {
            return nextRes;
          } else {
            return prevRes;
          }
        });
        newResult = {...newResult, [uniqueId]: nextResult};
      }
      setResult(newResult);
    };

    let id = setInterval(fnc, duration);
    return () => {
      if (id) {
        /**
         * duration 이 바뀌었을때, 한번 실행
         */
        fnc();
        clearInterval(id);
      }
    };
  }, [duration]);

  const addNodes = async (id: string, nodeInfos: INodeInfo[]) => {
    // const res = await getTagDataListReturnFromILatestTags(latestTags, option);
    const node_infos = nodeInfos.map((item) => ({
      database: item.database,
      node: [...item.hierarchy, item.name],
      [option.type]: option[option.type]
    }));
    const res = await api.post<IApiReturnBasic>('/node_manage/get_hierarchy_node_data_list', {node_infos});
    const nodeDataList = res.data as IReturnGetNodeData[];
    const now = Date.now();
    const refined = nodeDataList.map((item) => {
      const db = item.database;
      return item.data.map((d) => {
        const stringArray = d.feature.split(', ');
        return {
          key: [db, ...stringArray].join(', '),
          value: d.records,
          updateTime: now
        };
      }) as unknown[];
    });

    const newInfo = refined.flat(1) as ILatestNode[];
    const prevResult = {...resultRef.current};
    if (prevResult[id]) {
      const targetIdResult = prevResult[id] as ILatestNode[];
      const delDuplicate = targetIdResult.filter((prevRes) => !newInfo.some((newRes) => newRes.key === prevRes.key));
      const renewInfo = [...(delDuplicate || []), ...(newInfo || [])];
      setResult((prev) => ({
        ...prev,
        [id]: renewInfo
      }));
    } else {
      setResult((prev) => ({
        ...prev,
        [id]: newInfo
      }));
    }
  };

  /**
   *
   * @param id
   * @param nodeInfos
   * @param activate
   */
  const renewSubscribe = (id: string, nodeInfos: INodeInfo[], activate: boolean): number => {
    const prevResult = {...resultRef?.current} as ILatestNodeResult;
    const info = prevResult[id] as ILatestNode[];
    const tagsExcludeBlank = nodeInfos.filter((item) => item?.name);
    const refined = tagsExcludeBlank;
    // 처음 구독
    setSubscribe((prev) => ({
      ...prev,
      [id]: {
        targetList: refined,
        activate
      }
    }));
    let isNeedFetch: boolean;

    if (!subscribe[id] || subscribe[id]?.targetList?.length === 0) {
      isNeedFetch = true;
    } else {
      /**
       * 갱신된 subscription 정보가 prevSubscription 에 포함관계라면,
       * 그리고 autoUpdate가 false라면 api fetch 가 필요없다
       * api => autoUpdate || 최초 load 시에만 호출
       *
       * 이미 존재하는 노드리스트 에 포함되어있지 않은것이
       * 새롭게 갱신된 리스트에 잇다
       */
      isNeedFetch = refined.some((item) =>
        [...(subscribe[id].targetList || [])].some(
          (subscriptionInfo) =>
            [subscriptionInfo.database, ...subscriptionInfo.hierarchy, subscriptionInfo.name].join(', ') !==
            [item.database, ...item.hierarchy, item.name].join(', ')
        )
      );
    }
    if (!activate && !isNeedFetch) {
      return;
    }
    if (!info) {
      addNodes(id, refined);
      return refined.length;
    }
    // 구독이 되어있지만 새로 정보를 가져올 필요가 있음
    else if (info) {
      const newSubscribeItem = refined.filter(
        (newTags) =>
          !info.some((item) => item.key === [newTags.database, ...newTags.hierarchy, newTags.name].join(', '))
      );
      if (newSubscribeItem?.length > 0) {
        addNodes(id, newSubscribeItem);
        return newSubscribeItem.length;
      }
    }
    return 0;
  };

  const afterUnMountListener = (id: string) => {
    setSubscribe(function (prev) {
      const copy = {...prev};
      delete copy[id];
      return copy;
    });
  };

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration);
  };

  const onLoadLatestNodeResult = (tagResult: ILatestNodeResult) => {
    setResult(tagResult);
  };

  return {
    subscribe,
    latestResult: result,
    disconnectSubscribe: afterUnMountListener,
    renewSubscribe,
    changeDuration,
    onLoadLatestNodeResult
  };
}

export default useLatestNodeHandler;
