import {Context, createContext, PropsWithChildren, ReactElement, useReducer, useState} from 'react';
import {IAppMenu} from 'components/menu/types';
import {getUniqueKey} from 'utils/commons';
import {IDragItem, ITransferData, ITransferDataTypes} from 'components/dnd/types';
import {IContextMenu} from 'components/common/context-menu/ContextMenu';
import packageInfo from '../../../package.json';
import {VariantTypes} from 'components/forms/types';

export type IToast = {
  id?: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  text: string;
  delay?: number;
};

export type INotice = {
  id?: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  text: string;
  delay?: number;
};

type IModal = {
  title?: string | null;
  content: string | ReactElement | ReactElement[];
  message?: string;
  isShowCancel?: boolean;
  isShowClose?: boolean;
  isShowConfirm?: boolean;
  confirmLabel?: string | null;
  confirmVariant?: VariantTypes;
  cancelLabel?: string | null;
  closeLabel?: string | null;
  type?: 'normal';
  onCancel?(): void;
  onConfirm?(): void;
  onClose?(): void;
};

type ISplashModal = {
  title?: string | null;
  content?: string | ReactElement;
  image?: string | ReactElement;
};

type ISpinner = {
  offsetY?: number;
  radius?: number;
  hasText?: boolean | undefined;
  text?: string;
};

type ToastActionTypes = {type: 'ADD'; toast: IToast} | {type: 'REMOVE'; id: string};

type ModalActionTypes = {type: 'SHOW'; modal: IModal} | {type: 'HIDE'};

type SplashModalActionTypes = {type: 'SHOW'; modal: ISplashModal} | {type: 'HIDE'};

type SpinnerActionTypes = {type: 'SHOW'; spinner: ISpinner} | {type: 'HIDE'};

export type ICommonContext = {
  version: string;
  toastList: IToast[];
  notice: INotice | null;
  modal: IModal;
  splashModal: ISplashModal;
  spinner: ISpinner;
  remoteAppMenu: IAppMenu | null;
  addToast(toast: IToast): string;
  addNotice(notice: INotice): string;
  showModal(modal: IModal): void;
  hideModal(): void;
  showSplashModal(modal: ISplashModal): void;
  hideSplashModal(): void;
  showSpinner(spinner: ISpinner): void;
  hideSpinner(): void;
  showActionMenu(appMenu: IAppMenu): void;
  dragItem: IDragItem;
  afterDragStart(dragTransferData: ITransferData, type: ITransferDataTypes): void;
  afterDragEnd(): IDragItem;
  dragOverZoneId: string;
  onDragOverSettingZoneId(id: string): void;
  remoteTooltip: string | boolean | ReactElement;
  showRemoteTooltip(content: string | boolean | ReactElement): void;
  contextMenu: IContextMenu | null;
  showContextMenu(info: IContextMenu | null): void;
};

function reduceToastList(state: IToast[], action: ToastActionTypes): IToast[] {
  switch (action.type) {
    case 'ADD':
      return [...state, action.toast];
    case 'REMOVE':
      return state?.filter((toast) => toast.id !== action.id);
    default:
      return [];
  }
}

function reduceModal(state: IModal, action: ModalActionTypes): IModal {
  switch (action.type) {
    case 'SHOW':
      return {
        ...action.modal,
        type: action.modal.type ? action.modal.type : 'normal',
        isShowConfirm: action.modal.isShowConfirm !== undefined ? action.modal.isShowConfirm : true
      };
    case 'HIDE':
      return null;
    default:
      return null;
  }
}

function reduceSplashModal(state: ISplashModal, action: SplashModalActionTypes): ISplashModal {
  switch (action.type) {
    case 'SHOW':
      return {
        ...action.modal
      };
    case 'HIDE':
      return null;
    default:
      return null;
  }
}

function reduceSpinner(state: ISpinner, action: SpinnerActionTypes): ISpinner {
  switch (action.type) {
    case 'SHOW':
      return action.spinner;
    case 'HIDE':
      return null;
    default:
      return null;
  }
}

const defaultToastState: IToast[] = [];
const defaultModalState: IModal | null = null;
const defaultSplashModalState: ISplashModal | null = null;
const defaultSpinnerState: ISpinner | null = null;

export const CommonContext: Context<ICommonContext> = createContext(null);

function CommonProvider({children}: PropsWithChildren) {
  const [toastList, dispatchToastList] = useReducer(reduceToastList, defaultToastState);
  const [notice, setNotice] = useState(null);
  const [modal, dispatchModal] = useReducer(reduceModal, defaultModalState);
  const [splashModal, dispatchSplashModal] = useReducer(reduceSplashModal, defaultSplashModalState);
  const [spinner, dispatchSpinner] = useReducer(reduceSpinner, defaultSpinnerState);
  // AppMenu 가 아닌 곳에서 AppMenu 를 펼쳐야 할 때 사용
  const [remoteAppMenu, setRemoteAppMenu] = useState<IAppMenu>();
  const [remoteTooltip, setRemoteTooltip] = useState<string | boolean | ReactElement>();

  const addToast = (toast: IToast) => {
    dispatchToastList({type: 'ADD', toast});
    // const delay = toast.delay || 3000000; //css test
    toast.id = toast.id || getUniqueKey();
    toast.type = toast.type || 'success';
    const delay = toast.delay || 3000; // origin code

    setTimeout(() => {
      dispatchToastList({type: 'REMOVE', id: toast.id});
    }, delay);

    return toast.id;
  };

  const addNotice = (notice: INotice): string => {
    const obj = {
      ...notice,
      id: notice.id || getUniqueKey(),
      type: notice.type || 'success',
      delay: notice.delay || 3000
    };
    setNotice(obj);
    return obj.id;
  };

  const showModal = (modal: IModal) => {
    dispatchModal({
      type: 'SHOW',
      modal: {
        ...modal,
        isShowConfirm: modal.isShowConfirm !== undefined ? modal.isShowConfirm : true
      }
    });
  };

  const hideModal = () => {
    dispatchModal({type: 'HIDE'});
  };

  const showSplashModal = (modal: ISplashModal) => {
    dispatchSplashModal({
      type: 'SHOW',
      modal: {
        ...modal
      }
    });
  };

  const hideSplashModal = () => {
    dispatchSplashModal({type: 'HIDE'});
  };

  const showSpinner = (spinner: ISpinner) => {
    dispatchSpinner({type: 'SHOW', spinner});
  };

  const hideSpinner = () => {
    dispatchSpinner({type: 'HIDE'});
  };

  /**
   *  AppMenu 가 아닌 곳에서 AppMenu 를 펼쳐야 할 때 사용
   * @param appMenu
   */
  const showActionMenu = (appMenu: IAppMenu): void => {
    setRemoteAppMenu(appMenu);
  };

  /**
   * state 하나로 관리할것
   */
  const [dragItem, setDragItem] = useState<IDragItem>(null);
  const [dragOverZoneId, setDragOverZoneId] = useState('');

  const afterDragStart = (dragTransferData: ITransferData, type: ITransferDataTypes) => {
    setDragItem({dragTransferData, type});
  };

  const afterDragEnd = () => {
    const dragItemCopy = {...dragItem};
    setDragItem(null);
    setDragOverZoneId('');
    return dragItemCopy;
  };

  const onDragOverSettingZoneId = (id: string) => {
    setDragOverZoneId(id);
  };

  /**
   *
   * @param content
   */
  const showRemoteTooltip = (content: string | boolean | ReactElement): void => {
    setRemoteTooltip(content);
  };

  // const [contextMenuList, setContextMenuList] = useState<IContextMenuItem[]>(null);
  // const [contextMenuEvent, setContextMenuEvent] = useState<MouseEvent>(null);
  const [contextMenu, setContextMenu] = useState<IContextMenu>(null);
  const showContextMenu = (info: IContextMenu | null): void => {
    setContextMenu(info);
    // setContextMenuEvent(event);
    // setContextMenuList(menuList);
  };

  return (
    <CommonContext.Provider
      value={{
        version: packageInfo.version,
        toastList,
        notice,
        modal,
        splashModal,
        spinner,
        remoteAppMenu,
        addToast,
        addNotice,
        showModal,
        hideModal,
        showSplashModal,
        hideSplashModal,
        showSpinner,
        hideSpinner,
        showActionMenu,
        dragItem,
        afterDragStart,
        afterDragEnd,
        dragOverZoneId,
        onDragOverSettingZoneId,
        remoteTooltip,
        showRemoteTooltip,
        contextMenu,
        showContextMenu
      }}
    >
      {children}
    </CommonContext.Provider>
  );
}

export default CommonProvider;
