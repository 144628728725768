import {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {SelectForm} from 'components/forms';
import {ISeries} from 'components/pc/widgets/timeseries/types';

type IProps<T> = {
  flattenKeys: string;
  parallel: number;
  rowIndex: number;
  rows: T[];
  onChange(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void;
};

/**
 * DefaultTable 내부에서 checkbox 변경
 * @param flattenKeys
 * @param parallel
 * @param rows
 * @param onChange
 * @constructor
 */
function ParallelSelector<T extends ISeries>({flattenKeys, parallel, rows, onChange}: IProps<T>): ReactElement {
  const options = new Array(rows.length).fill(0).map((row, i) => ({label: 'Axis ' + (i + 1), value: i}));

  return (
    <SelectForm name={flattenKeys} title="parallel" value={parallel} options={options} width={80} onChange={onChange} />
  );
}

export default ParallelSelector;
