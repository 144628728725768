import {createContext, Context, PropsWithChildren, useState, RefObject} from 'react';
import {DatabaseHierarchy} from 'api/data-types';
import {WidgetTypes} from 'components/menu/types';
import {IPythonEditorOutflowResult} from 'components/pc/widgets/PythonEditorWidget';
import {IPythonEditorLog, IPythonEditorSimulationResult} from 'components/pc/widgets/pythonEditor/types';
import {Edge} from '@reactflow/core/dist/umd/types/edges';
import {Node} from 'reactflow';

export type ILocalDatabaseContext = {
  enrollmentInfo: IWidgetEnrollmentInfo;
  contentInfo: IWidgetContentInfo;
  updateSubjectInfo(
    id: string,
    type: WidgetTypes,
    ref: RefObject<PythonEditorSubjectInfo | DatasheetSubjectInfo>
  ): void;
  afterEdgesChange(nodes: Node[], edges: Edge[]): void;
};
export const LocalDatabaseContext: Context<ILocalDatabaseContext> = createContext(null);

export const localDatabaseRelatedWidgetList = ['DatasheetLocalDbWidget', 'PythonEditorWidget', 'MetaPfdWidget'];

export type ILocalDatabase = {
  [hashKey: string]: LocalDatabaseEntry[];
};

type LocalDatabaseType = 'PythonEditor' | 'Datasheet' | 'MetaPfd';

export type ILocalDatabaseDetail = {
  db: ILocalDatabase;
  type: LocalDatabaseType;
  hierarchy: DatabaseHierarchy;
  createAt?: number;
  updateAt?: number;
  name: string;
};

export type LocalDatabaseEntry = {
  timestamp?: number;
  value: string | number;
  type?: 'Inflow' | 'Outflow' | string;
};

type IWidgetEnrollmentInfo = {
  [subscriberId: string]: {id: string; type: WidgetTypes}[];
};

type IWidgetContentInfo = {
  [subjectWidgetId: string]: IWidgetSubjectInfoDetail;
};

type IWidgetSubjectInfoBasic = {
  id: string;
  name: string;
};

export type IWidgetSubjectInfoDetail = IPythonEditorWidgetSubjectInfoDetail | IDatasheetLocalDbWidgetSubjectInfoDetail;

export type IPythonEditorWidgetSubjectInfoDetail = IWidgetSubjectInfoBasic & {
  type: 'PythonEditorWidget';
  ref: RefObject<PythonEditorSubjectInfo>;
};

export type IDatasheetLocalDbWidgetSubjectInfoDetail = IWidgetSubjectInfoBasic & {
  type: 'DatasheetLocalDbWidget';
  ref: RefObject<DatasheetSubjectInfo>;
};

type PythonEditorSubjectInfo = {
  pythonRunOutflowResult: IPythonEditorOutflowResult;
  pythonEditorWidgetLogHistory: IPythonEditorLog[];
  pythonEditorSimulationResultHistory: IPythonEditorSimulationResult[];
  clearLogHistory(): void;
  afterRemovePythonLogWidget(): void;
  afterRemovePythonSimulationWidget(): void;
};

type DatasheetSubjectInfo = {
  dbData: any[][];
  focusedRowIdx: number;
  next(): Promise<boolean>;
  changeReadOnly(readOnly: boolean): Promise<boolean>;
};

function LocalDatabaseProvider({children}: PropsWithChildren) {
  const [enrollmentInfo, setEnrollmentInfo] = useState<IWidgetEnrollmentInfo>();
  const [contentInfo, setContentInfo] = useState<IWidgetContentInfo>();

  const updateSubscriptionInfo = (subscription: IWidgetEnrollmentInfo) => {
    setEnrollmentInfo(subscription);
  };

  const updateSubjectInfo = (
    id: string,
    type: 'PythonEditorWidget' | 'DatasheetLocalDbWidget' | string,
    ref: RefObject<PythonEditorSubjectInfo | DatasheetSubjectInfo>
  ) => {
    switch (type) {
      case 'DatasheetLocalDbWidget': {
        const refObject = ref as RefObject<DatasheetSubjectInfo>;
        setContentInfo((prev) => ({
          ...prev,
          [id]: {
            type,
            ref: refObject,
            id,
            name: id
          }
        }));
        break;
      }
      case 'PythonEditorWidget': {
        const refObject = ref as RefObject<PythonEditorSubjectInfo>;
        setContentInfo((prev) => ({
          ...prev,
          [id]: {
            type,
            ref: refObject,
            id,
            name: id
          }
        }));
      }
    }
  };

  const afterEdgesChange = (nodes: Node[], edges: Edge[]) => {
    const subscript = {};
    for (let i = 0; i < edges?.length; i++) {
      const edge = edges[i] as Edge;
      const sourceNodeId = edge.source;
      const targetNodeId = edge.target;

      const sourceNode = nodes.find((item) => item.id === sourceNodeId);
      const targetNode = nodes.find((item) => item.id === targetNodeId);

      if (
        localDatabaseRelatedWidgetList.includes(targetNode?.type) &&
        localDatabaseRelatedWidgetList.includes(sourceNode?.type)
      ) {
        const targetSubscript = subscript[targetNodeId];
        if (targetSubscript) {
          subscript[targetNodeId] = [...targetSubscript, {id: sourceNodeId, type: sourceNode.type}];
        } else {
          subscript[targetNodeId] = [{id: sourceNodeId, type: sourceNode.type}];
        }
      }
    }
    updateSubscriptionInfo(subscript);
  };

  const collection = {
    enrollmentInfo,
    contentInfo,
    updateSubjectInfo,
    afterEdgesChange
  };

  return <LocalDatabaseContext.Provider value={collection}> {children}</LocalDatabaseContext.Provider>;
}

export default LocalDatabaseProvider;
