import styled from 'styled-components';
import {DatabaseHierarchy} from 'api/data-types';
import Popup from 'components/popup/Popup';
import {Button} from 'components/forms';
import {BaseSyntheticEvent, useContext, useEffect, useMemo, useState} from 'react';
import NodeMenu from 'components/pc/node-selector/NodeMenu';
import NodeSelectorSearch from 'components/pc/node-selector/parts/NodeSelectorSearch';
import {CustomNode} from 'components/pc/node-selector/TreeMenu';
import {
  convertHierarchyToTreeNode,
  IConvertHierarchyOptions
} from 'components/pc/node-selector/parts/convertHierarchyToTreeNode';
import {DataContext} from 'api/DataProvider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 200;

  /*  h3 {
    height: 20px;
    line-height: 20px;
    margin: 0;
    font-size: 20px;
    color: #575660;
  }*/
`;

const NodeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
`;

const Footer = styled.div`
  button {
    width: 100%;
    height: 50px;
    font-size: 15px;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    em {
      color: tomato;
      font-style: normal;
    }
  }
`;

export type ICheckBoxTreeOptions = {
  onlyLeafCheckboxes?: boolean;
  expandUntilSearchString?: boolean; // 현재까지는 SearchString이 하나일때 (배열 x)만 작동하도록 구현 함.
};

type IProps = {
  title?: string;
  // selectedNodes?: INodeInfo[];
  selectedNodes?: string[][];
  hierarchyInfos?: DatabaseHierarchy[];
  options?: IConvertHierarchyOptions;
  onClose(): void;
  onSelect(checked: string[]): void;
  beforeOnCheck?(candidate: string[], checkedList: string[]): string[];
  checkBoxTreeOptions?: ICheckBoxTreeOptions;
};

function NodeSelectorRevision({
  title,
  selectedNodes,
  hierarchyInfos,
  options,
  onClose,
  onSelect,
  beforeOnCheck,
  checkBoxTreeOptions
}: IProps) {
  const {availableDatabaseHierarchyList} = useContext(DataContext);
  const checkedState = useState<string[]>([]);
  const expandedState = useState<string[]>();
  const nodesState = useState<CustomNode[]>([]);
  const keywordState = useState<string>('');
  const [nodes, setNodes] = nodesState;
  const [checkedList, setCheckedList] = checkedState;
  const [, setExpandedList] = expandedState;
  // console.log(availableDatabaseHierarchyList);

  const originNodes = useMemo(
    () => convertHierarchyToTreeNode(availableDatabaseHierarchyList, options, hierarchyInfos),
    [availableDatabaseHierarchyList, options, hierarchyInfos]
  );

  const getUniqueSubArrays = (arr: string[][]) => {
    const seen = new Set();
    return arr.filter((subArray) => {
      const serialized = JSON.stringify(subArray);
      return seen.has(serialized) ? false : seen.add(serialized);
    });
  };
  const getUniquePrefixes = (arr: string[][]) => {
    const uniquePrefixes = new Set();
    const result = [];
    arr.forEach((subArray) => {
      subArray.forEach((_, index) => {
        const prefix = subArray.slice(0, index + 1);
        const serialized = JSON.stringify(prefix);
        if (!uniquePrefixes.has(serialized)) {
          uniquePrefixes.add(serialized);
          result.push(prefix);
        }
      });
    });
    return result;
  };

  useEffect(() => {
    setNodes(originNodes);
  }, [originNodes]);

  useEffect(() => {
    // ["model-fcc, feed, temperatrue", .....]
    // const alreadyChecked = [...(selectedNodes || [])].map((item) =>
    //   JSON.stringify([item.database, ...item.hierarchy, item.name])
    // );
    // let alreadyExpanded;
    // const alreadyChecked = [...(selectedNodes || [])]
    // setCheckedList(alreadyChecked);
    // alreadyExpanded = [...(selectedNodes || [])].map((item) => {
    //   return [item.database, ...item.hierarchy];
    // });
    // const alreadyExpanded = [...(selectedNodes || [])]
    // const uniqueSubArr = getUniqueSubArrays(alreadyExpanded);
    // const uniquePrefixes = getUniquePrefixes(alreadyExpanded);
    // setExpandedList(uniquePrefixes.map((item) => JSON.stringify(item)));
    if (selectedNodes?.length > 0) {
      const checkedTagList = [...(selectedNodes || [])].map((tag) => JSON.stringify(tag));
      setCheckedList(checkedTagList);
      const expandedTagCandidateList = [];
      [...(selectedNodes || [])].map((tag) =>
        tag?.map((item, idx) => expandedTagCandidateList.push(tag.slice(0, idx + 1)))
      );
      const uniqueExpand = Array.from(new Set(expandedTagCandidateList.map((item) => JSON.stringify(item))));
      setExpandedList(uniqueExpand);
    }
  }, [options?.searchStrings, selectedNodes]);

  const onSelectNodes = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    onSelect(checkedList);
    onClose();
  };

  const beforeOnCheckWrapper = (candidate: string[], checkedList: string[]) => {
    if (options?.selectSingleNode) {
      if (candidate?.length === 1 || candidate?.length === 0) {
        return candidate;
      } else {
        const filtered = candidate.filter((item) => !checkedList.find((c) => item === c));
        if (filtered.length === 1) {
          return filtered;
        } else {
          return checkedList;
        }
      }
    } else if (beforeOnCheck) {
      return beforeOnCheck?.(candidate, checkedList);
    } else {
      return candidate;
    }
  };

  return (
    <Container>
      <Popup type="doubleModal" title={title ? title : 'Nodes Selector'} onClose={onClose}>
        <NodeMenuContainer>
          <NodeSelectorSearch
            checkedState={checkedState}
            expandedState={expandedState}
            keywordState={keywordState}
            nodesState={nodesState}
            originNodes={originNodes}
          />
          <NodeMenu
            nodes={nodes}
            checkedState={checkedState}
            expandedState={expandedState}
            beforeOnCheck={beforeOnCheckWrapper}
            checkBoxTreeOptions={checkBoxTreeOptions}
          />
          <Footer>
            <Button variant="light" disabled={checkedList.length > 1000} onClick={onSelectNodes}>
              {checkedList.length} Node{checkedList.length > 1 && 's'} selected
            </Button>
          </Footer>
        </NodeMenuContainer>
      </Popup>
    </Container>
  );
}

export default NodeSelectorRevision;
