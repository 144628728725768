import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import React, {CSSProperties, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {IPythonEditorWidgetData, IWidgetNodeData} from 'components/pc/types';
import PythonEditorBodyTop from 'components/pc/widgets/pythonEditor/PythonEditorBodyTop';
import PythonEditorBodyBottom from 'components/pc/widgets/pythonEditor/PythonEditorBodyBottom';
import PythonCodeEditor from 'components/pc/widgets/pythonEditor/PythonCodeEditor';
import PythonCodeResult from 'components/pc/widgets/pythonEditor/PythonCodeResult';
import PythonEditorActionPanel from 'components/pc/widgets/pythonEditor/PythonEditorActionPannel';
import PythonEditorVariableTable from 'components/pc/widgets/pythonEditor/PythonEditorVariableTable';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {
  INodeSelectorTargetInfo,
  IPythonEditorCodRunResult,
  IPythonEditorLog,
  IPythonEditorRunConfig,
  IPythonEditorSimulationResult,
  IPythonEditorVariable,
  IReturnPythonEditorCodeRun,
  IValueGroupObj,
  PythonLogActionType
} from 'components/pc/widgets/pythonEditor/types';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import useApi from 'api/useApi';
import {IApiReturnBasic} from 'api/data-types';
import {IDatasheetLocalDbWidgetSubjectInfoDetail, LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {pythonEditorDefaultStateValue, pythonEditorLogMsg} from 'components/pc/widgets/pythonEditor/constants';
import WidgetModal from 'components/spreadsheet/parts/WidgetModal';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import PythonEditorWidgetEnvironmentPackageList from 'components/pc/widgets/pythonEditor/PythonEditorWidgetEnvironmentPackageList';
import {convert2DRawArrToDbObject} from 'api/function';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {getUniqueKey} from 'utils/commons';
import dayjs from 'dayjs';
import WidgetIconModeHeader from 'components/pc/widgets/parts/WidgetIconModeHeader';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import useDraggable from 'utils/useDraggable';
import styled from 'styled-components';
import usePythonEnv from 'components/pc/widgets/pythonEditor/usePythonEnv';

const TopResizeArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
`;

export type IPythonEditorOutflowResult = {
  timestamp: number;
  outflowResult: {
    [key: string]: string | number;
  };
};

function PythonEditorWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const variableTableState = useState<IPythonEditorVariable[]>([]);
  const runLogHistoryState = useState<IPythonEditorLog[]>([]);
  const codeState = useState<string>(pythonEditorDefaultStateValue.codeState);
  const runConfigState = useState<IPythonEditorRunConfig>(pythonEditorDefaultStateValue.runConfigState);
  const valueGroupObjectState = useState<IValueGroupObj>({});
  const nodeSelectorTargetInfoState = useState<INodeSelectorTargetInfo>(null);
  const codRunResultState = useState<IPythonEditorCodRunResult>(null);

  const [, setValueGroupObject] = valueGroupObjectState;
  const [variableTable, setVariableTable] = variableTableState;

  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  const api = useApi();
  const reactFlow = useReactFlow();

  const boundaryTopRef = useRef(null);
  const dragHandleTopRef = useRef(null);

  const defaultTopPosition = {x: 0, y: 90};
  const defaultTopPadding = {top: 90, right: 0, bottom: 1, left: 0};
  const [editorBodyTopPosition, onMouseDownTopResizeHandle, setEditorBodyTopSize] = useDraggable(
    boundaryTopRef,
    dragHandleTopRef,
    defaultTopPosition,
    defaultTopPadding
  );

  const boundaryBottomRef = useRef(null);
  const dragHandleBottomRef = useRef(null);
  const defaultBottomPosition = {x: 550, y: 0};
  const defaultBottomPadding = {top: 0, right: 192, bottom: 0, left: 250};
  const [editorBodyBottomPosition, onMouseDownBottomResizeHandle, setEditorBodyBottomSize] = useDraggable(
    boundaryBottomRef,
    dragHandleBottomRef,
    defaultBottomPosition,
    defaultBottomPadding
  );

  const {enrollmentInfo, contentInfo, updateSubjectInfo} = useContext(LocalDatabaseContext);

  const [pythonEditorWidgetLogHistory, setPythonEditorWidgetLogHistory] = runLogHistoryState;
  const [pythonRunOutflowResult, setPythonRunOutflowResult] = useState<IPythonEditorOutflowResult>();
  const [connectedDatasheetSubjectInfo, setConnectedDatasheetSubjectInfo] =
    useState<IDatasheetLocalDbWidgetSubjectInfoDetail>();

  const isShowWidgetModalState = useState(false);
  const [isShowWidgetModal, setIsShowWidgetModal] = isShowWidgetModalState;
  const isShowEnvironmentPackageListModalState = useState(false);
  const [isShowEnvironmentPackageListModal, setIsShowEnvironmentPackageListModal] =
    isShowEnvironmentPackageListModalState;

  const [queueResetTimeoutId, setQueueResetTimeoutId] = useState<NodeJS.Timeout>(null);
  const queueResetTimeoutIdRef = useRef(null);
  queueResetTimeoutIdRef.current = queueResetTimeoutId;

  const isRunState = useState(false);
  const [isRun, setIsRun] = isRunState;
  const isRunRef = useRef(null);
  isRunRef.current = isRun;

  const [stopRequest, setSopRequest] = useState(null);
  const stopRequestRef = useRef(null);
  stopRequestRef.current = stopRequest;

  const [packageList, setPackageList] = useState<string[]>([]);

  const [environmentList, selectedEnv, changeEnv] = usePythonEnv();

  const clearLogHistory = () => {
    setPythonEditorWidgetLogHistory([]);
  };

  const [pythonEditorLogWidgetId, setPythonEditorLogWidgetId] = useState<string>(null);

  const afterRemovePythonLogWidget = () => {
    setPythonEditorLogWidgetId(null);
  };

  const [pythonEditorSimulationResultHistory, setPythonEditorSimultationResultHistory] = useState<
    IPythonEditorSimulationResult[]
  >([]);
  const [pythonEditorSimulationResultWidgetId, setPythonEditorSimulationResultWidgetId] = useState<string>(null);

  const afterRemovePythonSimulationWidget = () => {
    setPythonEditorSimulationResultWidgetId(null);
  };

  const iconModeState = useState<CSSProperties>(null);
  const [iconMode, setIconMode] = iconModeState;

  const subjectRef = useRef(null);
  subjectRef.current = {
    pythonRunOutflowResult,
    pythonEditorWidgetLogHistory,
    pythonEditorSimulationResultHistory,
    clearLogHistory,
    afterRemovePythonLogWidget,
    afterRemovePythonSimulationWidget
  };

  useEffect(() => {
    updateSubjectInfo(id, 'PythonEditorWidget', subjectRef);
  }, []);

  useEffect(() => {
    const widgetSubscriptionInfo = enrollmentInfo?.[id];
    const subjectInfoArray = [];
    if (widgetSubscriptionInfo) {
      widgetSubscriptionInfo?.forEach((info) => {
        const widgetSubjectInfo = contentInfo?.[info.id];
        subjectInfoArray.push(widgetSubjectInfo);
      });
    }
    if (subjectInfoArray?.[0]) {
      const subjectInfo = subjectInfoArray?.[0] as IDatasheetLocalDbWidgetSubjectInfoDetail;
      onChangeDatasheetReadOnly(subjectInfo);
    }
    setConnectedDatasheetSubjectInfo((prev) => {
      if (prev?.ref) {
        prev?.ref?.current?.changeReadOnly?.(false).then();
        setRunCfg((prev) => ({...prev, batchRun: false}));
      }
      return subjectInfoArray?.[0];
    });
  }, [enrollmentInfo, contentInfo]);

  const onChangeDatasheetReadOnly = (datasheetWidgetInfo: IDatasheetLocalDbWidgetSubjectInfoDetail) => {
    const ref = datasheetWidgetInfo?.ref;
    ref?.current?.changeReadOnly?.(runConfig?.batchRun).then();
  };

  const dynamicDbInfo =
    connectedDatasheetSubjectInfo?.type === 'DatasheetLocalDbWidget' &&
    convert2DRawArrToDbObject(
      connectedDatasheetSubjectInfo?.ref?.current?.dbData,
      connectedDatasheetSubjectInfo?.id,
      connectedDatasheetSubjectInfo?.name
    );
  const dynamicHierarchyInfo = dynamicDbInfo?.hierarchy;
  const wrapeddynamicHierarchyInfo = [dynamicHierarchyInfo];

  const focusedRowData = () => {
    const data = connectedDatasheetSubjectInfo?.ref?.current?.dbData;
    const result = {};
    if (!data) return result;

    let headersRowIndex = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].some((cell) => cell !== undefined && cell !== null && cell !== '')) {
        headersRowIndex = i;
        break;
      }
    }
    if (headersRowIndex === -1) return result;

    const headers = data[headersRowIndex];
    const rows = data?.slice(1); // header row slice

    const idx = connectedDatasheetSubjectInfo?.ref?.current?.focusedRowIdx - 1;
    if (idx < 0) {
      return result;
    }
    const row = rows?.[connectedDatasheetSubjectInfo?.ref?.current?.focusedRowIdx - 1];

    if (!row) {
      return result;
    }

    if (headers[0]?.toLowerCase()?.includes('time')) {
      for (let i = 1; i < headers.length; i++) {
        const tag = headers[i];
        if (!tag) continue;
        const value = row[i] !== undefined ? row[i] : null;
        result[tag] = value;
      }
    } else {
      for (let i = 0; i < headers.length; i++) {
        const tag = headers[i];
        if (!tag) continue;
        const value = row[i] !== undefined ? row[i] : null;
        result[tag] = value;
      }
    }
    return result;
  };

  const valueFromDatasheet = focusedRowData();

  const [code, setCode] = codeState;
  const [runConfig, setRunCfg] = runConfigState;
  const [codeRunResult, setCodeRunResult] = codRunResultState;
  const [nodeSelectorTargetInfo, setNodeSelectorTargetInfo] = nodeSelectorTargetInfoState;

  const variableTableRef = useRef(null);
  variableTableRef.current = variableTable;

  useEffect(() => {
    if (data?.metaData) {
      const {
        code,
        codeRunResult,
        runConfig,
        selectedEnvironment,
        variableTable,
        pythonEditorWidgetLogHistory,
        pythonEditorLogWidgetId,
        pythonEditorSimulationResultWidgetId,
        pythonEditorSimulationResultHistory,
        iconMode,
        resizeWidth,
        resizeHeight
      } = data?.metaData as IPythonEditorWidgetData;
      setCode(code || '');
      setCodeRunResult(codeRunResult);
      setRunCfg(runConfig || pythonEditorDefaultStateValue.runConfigState);
      setVariableTable(variableTable || []);
      changeEnv(selectedEnvironment || '');
      setPythonEditorWidgetLogHistory(pythonEditorWidgetLogHistory || []);
      setPythonEditorLogWidgetId(pythonEditorLogWidgetId || null);
      setPythonEditorSimultationResultHistory(pythonEditorSimulationResultHistory || []);
      setPythonEditorSimulationResultWidgetId(pythonEditorSimulationResultWidgetId || null);
      setIconMode(iconMode || null);
      setEditorBodyTopSize({x: 0, y: resizeHeight || 0});
      setEditorBodyBottomSize({x: resizeWidth || 0, y: 0});
    }
  }, []);

  useEffect(() => {
    /**
     *  resizeHeight, resizeWidth 바뀌는 callback으로 옮겨서
     *  useEffect 내에서 setNodes 제거 필요
     */
    setTimeout(() => {
      reactFlow.setNodes((nodes) =>
        nodes.map((node) => {
          if (node?.id !== id) return node;
          const {data} = node;
          const {metaData} = data || {};
          return {
            ...node,
            data: {
              ...data,
              metaData: {
                ...(metaData || {}),
                resizeHeight: editorBodyTopPosition.y,
                resizeWidth: editorBodyBottomPosition.x
              }
            }
          };
        })
      );
    });
  }, [editorBodyTopPosition, editorBodyBottomPosition]);

  /*useEffect(() => {
    api.get<IApiReturnBasic>('/python_editor/env_list').then(function (res) {
      if (res.success) {
        const envList = res.data as string[];
        setEnvList(envList);
        if (envList?.length > 0) {
          setSelectedEnv(envList?.[0]);
        }
      }
    });
  }, []);*/

  useEffect(() => {
    if (!id || !variableTable || variableTable?.length === 0) return;

    const cloudDbVariable = variableTable?.filter((item) => item.variableDbType === 'cloud');
    const cloudNodeInfos = cloudDbVariable.map((item) => {
      const path = item.path;
      if (path.length > 1) {
        const [database, ...rest] = path;
        const hierarchy = rest.slice(0, -1);
        const name = rest[rest.length - 1];
        return {
          name,
          database,
          hierarchy
        };
      }
    });
    latestNodeHandler.renewSubscribe(id, cloudNodeInfos, false);
  }, [id, variableTable]);

  useEffect(() => {
    if (!latestNodeHandler?.latestResult?.[id]) return;

    const cloudRes = [...(latestNodeHandler?.latestResult[id] || [])];
    let newValueGroupObject = {};
    setVariableTable(function (prevState) {
      let newState = prevState;
      if (cloudRes?.length > 0) {
        newState.map(function (row) {
          const targetRes = cloudRes.find((latestNode) => latestNode.key === row.path.join(', '));
          if (targetRes) {
            newValueGroupObject = {...newValueGroupObject, [row.keys]: targetRes};
          }
        });
      }
      setValueGroupObject(newValueGroupObject);
      afterChangeMetaData('variableTable', newState);
      return newState;
    });
  }, [id, latestNodeHandler?.latestResult]);

  useEffect(() => {
    if (selectedEnv) {
      getPythonEnvironmentPackageList();
    }
  }, [selectedEnv]);

  const getPythonEnvironmentPackageList = () => {
    api.get<IApiReturnBasic>('/python_editor/package_list/' + selectedEnv).then((res) => {
      if (res.success) {
        const d = res.data as string[];
        setPackageList(d);
      }
    });
  };

  const onChangeRunConfig = (key: string, value: string) => {
    switch (key) {
      case 'environment': {
        changeEnv(value);
        break;
      }
    }

    // todo: setState 의 순차 실행에 대한 refactoring 필요.
    setTimeout(() => {
      setRunCfg((prev) => {
        const next = {...prev, [key]: value};
        afterChangeMetaData('runConfig', next);
        return next;
      });
    }, 100);
  };

  const runCode = async () => {
    const start = Date.now();

    /**
     * queue 초기화 타이머가 돌고있다면 reset
     * Python code run result 가 Null 이 아닌 상태에서 새로운 result sheet 연결했을때 기존의 garbage 값이 보이는 것 방지
     */
    if (queueResetTimeoutIdRef.current) {
      clearTimeout(queueResetTimeoutIdRef.current);
      setQueueResetTimeoutId(null);
    }

    const inflowForLog = {};

    const variablesCode = await Promise.all(
      variableTable.map(async (row) => {
        if (row.flowType === 'Inflow') {
          const searchResult = valueGroupObjectState?.[0]?.[row.keys]?.value?.[0]?.[1];
          const valueFromDatasheet = focusedRowData();

          const searchResultFormDatasheet = valueFromDatasheet?.[row?.path?.[1]];
          if (searchResult) {
            inflowForLog[row?.variableName] = searchResult;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResult || '')}`;
          } else if (searchResultFormDatasheet) {
            inflowForLog[row?.variableName] = searchResultFormDatasheet;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResultFormDatasheet || '')}`;
          }
        }
        return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify('')}`;
      })
    );
    const outflowVariables = variableTable
      .filter((row) => row.flowType === 'Outflow')
      .map((row) => row.variableName.replace(/ /g, '_'));

    const pipInstallLines = code
      .split('\n')
      .filter((line) => line.startsWith('pip install'))
      .map((line) => line.replace('pip install ', ''));

    const requirements = pipInstallLines.join(',');

    const codeWithoutPip = code
      .split('\n')
      .filter((line) => !line.startsWith('pip install'))
      .join('\n');

    const fullCode = `
${variablesCode.join('\n')}
${codeWithoutPip}
outflow_results = {var: eval(var) for var in ${JSON.stringify(outflowVariables)}}
print("OUTFLOW_RESULTS:", outflow_results)
    `;

    addPythonLog('start', '', {inflow: inflowForLog});

    const response = await api.post<IReturnPythonEditorCodeRun>('/python_editor/run_code', {
      code: fullCode,
      env_name: selectedEnv,
      requirements: requirements
    });
    /**
     * Click Pause Btn
     */
    if (!isRunRef.current) {
      return;
    }

    const end = Date.now();
    const diff = end - start;
    const diffTime = dayjs(diff).format(diff > 6000 ? 'm [min] s [sec]' : 's [sec]');

    if (response.success) {
      setCodeRunResult(response.data);
      if (response?.data?.error) {
        setIsShowWidgetModal(true);
        setIsRun(false);
        addPythonLog('end', diffTime, {}, response.data);
        return;
      }
    }

    /**
     * pip install 결과가 있으면 package list 갱신
     */
    if (response?.data?.install_results) {
      getPythonEnvironmentPackageList();
    }

    const now = Date.now();
    const outFlowResult = JSON.parse(response.data.outflow_results);

    let outflowResult = {};

    Object.entries(outFlowResult).forEach(([key, value]) => {
      if (typeof value === 'number' || typeof value === 'string') {
        outflowResult = {...outflowResult, [key]: value};
      }
    });

    setPythonRunOutflowResult({timestamp: now, outflowResult});

    if (runConfig?.batchRun) {
      connectedDatasheetSubjectInfo?.ref?.current?.next().then((success) => {
        if (success) {
          if (runConfig?.autoRun && runConfig?.intervalValue > 0) {
            setTimeout(() => runCode(), runConfig?.intervalValue * runConfig?.intervalUnit);
          } else {
            setTimeout(() => runCode(), 0);
          }
        } else {
          setIsRun(false);
        }
      });
    } else {
      setIsRun(false);
    }
    addPythonLog('end', diffTime, {outflow: outflowResult}, response.data);
    setPythonEditorSimultationResultHistory((prev) => [
      ...prev,
      {timestamp: end, variableInfo: {outflow: outflowResult, inflow: inflowForLog}}
    ]);

    reserveClearResultQueue();

    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node?.id !== id) return node;
        const {data} = node;
        const {metaData} = data || {};
        return {
          ...node,
          data: {
            ...data,
            metaData: {
              ...(metaData || {}),
              pythonEditorSimulationResultHistory: [
                ...(pythonEditorSimulationResultHistory || []),
                {timestamp: end, variableInfo: {outflow: outflowResult, inflow: inflowForLog}}
              ],
              codeRunResult: response?.data || null,
              selectedEnvironment: selectedEnv || null
            }
          }
        };
      })
    );

    return response;
  };

  const reserveClearResultQueue = () => {
    const timer = setTimeout(() => setPythonRunOutflowResult(null), 5000);
    setQueueResetTimeoutId(timer);
  };

  const onSelectNodeSelector = (checked: string[]) => {
    const parsedData = checked.map((item) => JSON.parse(item));

    if (parsedData?.[0]) {
      const path = parsedData?.[0];
      let variableDbType = 'cloud';
      if (dynamicHierarchyInfo?.database === path?.[0]) {
        variableDbType = 'local';
      }
      setVariableTable((prev) =>
        prev.map((row) =>
          row.keys === nodeSelectorTargetInfo.key
            ? {
                ...row,
                variableDbType,
                path: parsedData?.[0]
              }
            : row
        )
      );
    }
    setNodeSelectorTargetInfo(null);
  };

  const onSetNodeSelectorTargetInfo = (key: string, path: string[]) => {
    setNodeSelectorTargetInfo({key, path});
  };

  const onCloseNodeSelector = () => {
    setNodeSelectorTargetInfo(null);
  };

  const stopPythonCodeRunning = () => {
    const now = Date.now();
    // api
    //   .post('/python_editor/stop_code', {
    //     env_name: selectedEnvironment
    //   })
    //   .then((res) => console.log(res));
    addPythonLog('stop');
    setSopRequest(now);
  };

  const addPythonLog = (
    actionType: PythonLogActionType,
    detail?: string,
    variableInfo?: {[typeKye: string]: {[key: string]: string | number}},
    apiRes?: IPythonEditorCodRunResult
  ) => {
    let msg = pythonEditorLogMsg[actionType];
    switch (actionType) {
      case 'end': {
        msg = msg + ': running time(' + detail + ')';
        break;
      }
      default:
      // nothing to do
    }
    const timestamp = Date.now();
    // setPythonEditorWidgetLogHistory((prev) => [...prev, {timestamp, msg, variableInfo, apiRes}]);
    setPythonEditorWidgetLogHistory(function (prev) {
      const next = [...prev, {timestamp, msg, variableInfo, apiRes}];
      afterChangeMetaData('pythonEditorWidgetLogHistory', next);
      return next;
    });
  };

  const addPythonLogWidget = () => {
    /**
     * 이미 존재할때
     */
    if (pythonEditorLogWidgetId) {
      reactFlow.setNodes((nodes) => nodes.filter((item) => item.id !== pythonEditorLogWidgetId));
      afterRemovePythonLogWidget();
      return;
    }

    const logId = getUniqueKey();
    setPythonEditorLogWidgetId(logId);
    const {x, y} = reactFlow.getNode(id).position;
    const {width, height} = reactFlow.getNode(id).style;
    const position = {x: x + Number(width) + 20, y};
    reactFlow.addNodes({
      id: logId,
      type: 'PythonEditorLogWidget',
      data: {
        title: data.title,
        customizedTitle: false,
        metaData: {
          targetPythonEditorId: id
        },
        minWidth: 300,
        minHeight: 300
      },
      style: {
        minWidth: 300,
        minHeight: 300,
        width: 300,
        height
      },
      zIndex: rest.zIndex,
      position
    });
    setTimeout(() => afterChangeMetaData('pythonEditorLogWidgetId', logId));
  };

  //pythonEditor widget title을 result, log widget의 title로 변경하는 코드
  const onChangeTitle = (title: string) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === pythonEditorSimulationResultWidgetId || node.id === pythonEditorLogWidgetId) {
          if (data.title.length === 0) {
            node.data.title = 'Untitled';
            node.data.customizedTitle = false;
          } else {
            node.data.title = title;
            node.data.customizedTitle = true;
          }
        }
        return node;
      })
    );
  };

  const addPythonSimulationWidget = () => {
    /**
     * 이미 존재할때
     */
    if (pythonEditorSimulationResultWidgetId) {
      reactFlow.setNodes((nodes) => nodes.filter((item) => item.id !== pythonEditorSimulationResultWidgetId));
      afterRemovePythonSimulationWidget();
      return;
    }

    const simulId = getUniqueKey();
    setPythonEditorSimulationResultWidgetId(simulId);
    const {x, y} = reactFlow.getNode(id).position;
    const {width, height} = reactFlow.getNode(id).style;
    const position = {x, y: y + Number(height) + 20};
    reactFlow.addNodes({
      id: simulId,
      type: 'PythonEditorSimulationResultWidget',
      data: {
        title: data.title,
        customizedTitle: false,
        metaData: {
          targetPythonEditorId: id
        },
        minWidth: 300,
        minHeight: 300
      },
      style: {
        minWidth: 300,
        minHeight: 300,
        width,
        height: 300
      },
      zIndex: rest.zIndex,
      position
    });
    setTimeout(() => afterChangeMetaData('pythonEditorSimulationResultWidgetId', simulId));
  };

  const onClickIconModeIcon = () => {
    setIconMode(function (prevState) {
      let prevStyle = null;
      if (prevState) {
        reactFlow.setNodes((nodes) =>
          nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: null}
                  },
                  style: prevState
                }
              : item
          )
        );
      } else {
        reactFlow.setNodes((nodes) => {
          prevStyle = nodes.find((item) => item.id === id).style;
          return nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: prevStyle}
                  },
                  style: {
                    width: 343,
                    height: 144,
                    maxWidth: 343,
                    maxHeight: 144,
                    minWidth: 343,
                    minHeight: 144
                  }
                }
              : item
          );
        });
      }
      return prevStyle;
    });
  };

  const afterChangeMetaData = (params: string, value: any) => {
    setTimeout(() => {
      reactFlow.setNodes((nodes) =>
        nodes.map((node) => {
          if (node?.id !== id) return node;
          const {data} = node;
          const {metaData} = data || {};
          return {
            ...node,
            data: {
              ...data,
              metaData: {
                ...(metaData || {}),
                [params]: value
              }
            }
          };
        })
      );
    }, 0);
  };

  const widgetBodyHeight = boundaryTopRef.current?.offsetHeight || 0;
  const widgetBottomHeight = widgetBodyHeight - editorBodyTopPosition.y - 7;

  return (
    <WidgetContainer {...rest} data={data} type="PythonEditorWidget" iconMode={Boolean(iconMode)}>
      <WidgetHeader
        type="PythonEditorWidget"
        icon={data.icon}
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'PythonEditorWidget', titleData: '', data})}
        suffix="- Python Editor"
        onChangeTitle={onChangeTitle}
      />
      {Boolean(iconMode) && (
        <WidgetIconModeHeader
          title={data.customizedTitle ? data.title : getWidgetTitle({type: 'PythonEditorWidget', titleData: '', data})}
        />
      )}
      <WidgetActionPanel padding={0} height={!iconMode ? 60 : 123} align="center">
        <PythonEditorActionPanel
          iconMode={Boolean(iconMode)}
          isRunState={isRunState}
          isShowEnvironmentPackageListModalState={isShowEnvironmentPackageListModalState}
          runConfigState={runConfigState}
          environmentList={environmentList}
          connectedDatasheetSubjectInfo={connectedDatasheetSubjectInfo}
          pythonEditorLogWidgetId={pythonEditorLogWidgetId}
          pythonEditorSimulationResultWidgetId={pythonEditorSimulationResultWidgetId}
          onChangeRunConfig={onChangeRunConfig}
          runCode={runCode}
          stopPythonCodeRunning={stopPythonCodeRunning}
          addPythonLogWidget={addPythonLogWidget}
          addPythonSimulationWidget={addPythonSimulationWidget}
          onClickIconModeIcon={onClickIconModeIcon}
        />
      </WidgetActionPanel>
      <WidgetBody ref={boundaryTopRef} actionMenuHeight={42}>
        {Boolean(nodeSelectorTargetInfoState[0]) && (
          <NodeSelectorRevision
            onSelect={onSelectNodeSelector}
            onClose={onCloseNodeSelector}
            hierarchyInfos={wrapeddynamicHierarchyInfo}
            options={{selectSingleNode: true}}
          />
        )}
        <PythonEditorBodyTop height={editorBodyTopPosition.y}>
          <PythonEditorVariableTable
            variableTableState={variableTableState}
            valueFromDatasheet={valueFromDatasheet}
            valueGroupObjectState={valueGroupObjectState}
            onSetNodeSelectorTargetInfo={onSetNodeSelectorTargetInfo}
          />
        </PythonEditorBodyTop>
        <TopResizeArea ref={boundaryBottomRef}>
          <ResizeHandle ref={dragHandleTopRef} onMouseDown={onMouseDownTopResizeHandle} />
          <PythonEditorBodyBottom height={widgetBottomHeight}>
            <PythonCodeEditor
              width={editorBodyBottomPosition.x}
              codeState={codeState}
              afterChangeMetaData={afterChangeMetaData}
            />
            {widgetBottomHeight > 32 && (
              <ResizeHandle type="vertical" ref={dragHandleBottomRef} onMouseDown={onMouseDownBottomResizeHandle} />
            )}
            <PythonCodeResult codRunResultState={codRunResultState} />
          </PythonEditorBodyBottom>
        </TopResizeArea>
        {isShowWidgetModal && (
          <WidgetModal title="Notice" onClose={() => setIsShowWidgetModal(false)}>
            Python code execution failed. <br /> Please check the error message.
          </WidgetModal>
        )}
      </WidgetBody>
      <WidgetConfigLayer
        title="Python Environment Package List"
        isShow={isShowEnvironmentPackageListModal}
        onClose={() => setIsShowEnvironmentPackageListModal(false)}
      >
        <PythonEditorWidgetEnvironmentPackageList
          pythonEnv={selectedEnv}
          packageList={packageList}
          onClose={() => setIsShowEnvironmentPackageListModal(false)}
        />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}
export default PythonEditorWidget;
