import {PropsWithChildren, ReactElement} from 'react';
import WidgetConfigLayerHeader from 'components/pc/widgets/parts/WidgetConfigLayerHeader';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div`
  position: absolute;
  top: 41px;
  width: 100%;
  height: calc(100% - 41px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  cursor: initial;
  z-index: 1000000000; // 3d widget LeftMenu z-index보다 높게 설정
`;

const LayerPanel = styled.div`
  background-color: #ffffff;
  min-width: 280px;
  max-width: 600px;
  width: 80%;
  height: 80%;
  margin: auto;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  &.small {
    height: 60%;
  }
`;

type IProps = PropsWithChildren & {
  title?: string;
  isShow?: boolean;
  disabled?: 'disabled' | 'enabled';
  size?: 'small' | 'medium' | 'large';
  onClose(): void;
};

/**
 * onClick e.stopPropagation() 이유
 * Widget 마다 onClick 시에 z Index 변경으로 setNodes() 를 사용하는데,
 * config 는 보통 setNodes 를 통해 저장하는 동작이 이루어지기 떄문에 둘이 충돌이 일어납니다
 */
function WidgetConfigLayer({children, title, isShow, onClose, disabled, size}: IProps): ReactElement {
  if (isShow)
    return (
      <Container className="widget-config-layer nodrag nowheel" onClick={(e) => e.stopPropagation()}>
        <LayerPanel className={classNames(size)}>
          <WidgetConfigLayerHeader title={title} onClose={onClose} disabled={disabled} />
          {children}
        </LayerPanel>
      </Container>
    );
  else return <></>;
}

export default WidgetConfigLayer;
