import {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {SizeTypes} from 'components/common/types';

const Container = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  transition: height 0.2s 1s;

  &.top-center {
    justify-content: center;
  }
  &.top-right {
    justify-content: flex-end;
  }
  &.top-left {
    justify-content: flex-start;
  }
  &.center-center {
    align-items: center;
    justify-content: center;
  }
  &.center-left {
    align-items: center;
    justify-content: flex-start;
  }
  &.center-right {
    align-items: center;
    justify-content: flex-end;
  }

  &.inline {
    width: auto;
    margin: initial;
  }
  &.overlay {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 600;
  }

  &.show {
    //height: 40px;
    transition: height 0.2s;
  }
`;

type StyleProps = {
  $transitionTime: number;
};

const SpinAnimation = styled.div<StyleProps>`
  opacity: 0;
  margin: auto;
  width: 34px;
  height: 34px;
  border: 3px solid #b6b4c0;
  border-bottom-color: #ee5c84;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  transition: opacity ${(props) => props.$transitionTime}s;
  //position: absolute;

  &.sm {
    border-width: 2px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  &.md {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }
  &.xs {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
  }
  &.fade-in {
    opacity: 1;
    transition: all 0.2s;
  }
  //&.top-right {
  //  margin: 0 0 auto auto;
  //}
  //&.top-left {
  //  top: 0;
  //  left: 0;
  //}
  //&.top-left {
  //  top: 0;
  //  left: 0;
  //}

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type IProps = {
  type?: 'default' | 'inline' | 'overlay';
  size?: SizeTypes;
  position?: 'top-center' | 'top-left' | 'top-right' | 'center-center' | 'center-left';
  margin?: number | string;
  isShow: boolean;
  delayTime?: number;
};

function BasicSpinner({
  type = 'default',
  size = 'lg',
  position = 'top-center',
  margin = 20,
  isShow,
  delayTime = 0.5 // 단위: sec
}: IProps): ReactElement {
  const [render, setRender] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isShow) {
      setRender(true);
    } else {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        setRender(false);
      }, delayTime * 1000);
    }
    return () => clearTimeout(timer);
  }, [isShow]);

  if (render) {
    return (
      <Container className={classNames(type, position, {show: isShow})}>
        <SpinAnimation $transitionTime={delayTime} style={{margin}} className={classNames(size, {'fade-in': isShow})} />
      </Container>
    );
  } else {
    return undefined;
  }
}

export default BasicSpinner;
