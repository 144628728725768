import {ChangeEvent, ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import NodeSelector from 'components/pc/node-selector/NodeSelector';
import {IAnnotation} from 'components/mpfd/type';
import {TreeCategory} from 'api/DataProvider';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {checkStream} from 'components/mpfd/panel/parts/property/function';
import {
  PortProperty,
  PropertyTable,
  StreamProperty,
  TagProperty,
  AnnotationName
} from 'components/mpfd/panel/parts/property';
import PropertyRow from 'components/mpfd/panel/parts/property/PropertyRow';
import {SwitchForm} from 'components/forms';
import {ITagData} from 'api/data-types';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {convertHierarchyToTreeNode} from 'components/pc/node-selector/parts/convertHierarchyToTreeNode';

const Container = styled.div`
  height: calc(100% - 70px);
`;
const PropertyBox = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  select {
    width: 250px;
  }
`;
const Title = styled.h2`
  padding: 0 20px;
  margin: 0;
  height: 40px;
  color: #aaa;
  background-color: #e8e8e8;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const propertyComponents = {
  'expanding-line': StreamProperty,
  point: TagProperty,
  box: TagProperty,
  port: PortProperty
};

function Properties(): ReactElement {
  const {state, dispatchToReducer} = useContext(MetaPfdContext);
  const [isShowTagSelector, setIsShowTagSelector] = useState(false);
  const [editTarget, setEditTarget] = useState<IAnnotation | null>(null);

  const [selectedAnnot, setSelectedAnnot] = useState<IAnnotation>();
  useEffect(() => {
    const selectedList = state?.regions?.filter((r) => r?.highlighted);
    const selected = selectedList.length === 1 ? selectedList[0] : null;

    setSelectedAnnot(selected);
    if (!selected) {
      setIsShowTagSelector(false);
      setEditTarget(null);
    } else {
      /**
       * 다른 annotation select시 tagselect 창 닫기
       */
      setEditTarget(function (prevTarget) {
        if (prevTarget?.id !== selected?.id) {
          setIsShowTagSelector(false);
          return selected;
        } else {
          return prevTarget;
        }
      });
    }
  }, [state?.regions]);

  const selectedNodes = useMemo(() => {
    return selectedAnnot?.metaPfdTagInfo?.map((item) => item?.tag) || [];
  }, [selectedAnnot?.metaPfdTagInfo]);

  if (!selectedAnnot) return;
  const {type} = selectedAnnot;
  const PropertyComponent = propertyComponents[type];
  if (!PropertyComponent) return;

  const onSelectTags = (checked: string[]): void => {
    setIsShowTagSelector(false);
    const decode = checked.map((item) => JSON.parse(item));
    dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {
        ...selectedAnnot,
        // tagInfo: {...selectedAnnot?.tagInfo, hierarchyInfo: tags, name: tags},
        metaPfdTagInfo: decode.map((tag) => ({
          tag,
          isVisible: true,
          unit: null, // :todo 복구
          isChartDisplay: true,
          isTableDisplay: true
        })),
        tablePosition: {
          ...(selectedAnnot?.tablePosition || {x: selectedAnnot?.x, y: selectedAnnot?.y})
        },
        chartPosition: {
          ...(selectedAnnot?.chartPosition || {x: selectedAnnot?.x, y: selectedAnnot?.y})
        }
      }
    });
  };

  const beforeOnCheck = (tags: string[], checked: string[]) => {
    if (type === 'expanding-line') {
      return checkStream(tags, checked);
    } else {
      return tags;
    }
  };

  const onChangeEditTarget = (target: Partial<IAnnotation> | null) => {
    if (target === null) {
      setEditTarget(null);
      return;
    }
    setEditTarget((prev) => ({...prev, ...target}));
  };

  const onChangeDataValues = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {...selectedAnnot, isTableVisible: !selectedAnnot?.isTableVisible}
    });
  };

  const onChangeIsChartVisible = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {...selectedAnnot, isNormalChartVisible: !selectedAnnot?.isNormalChartVisible}
    });
  };

  const onChangeShowTableHeader = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {...selectedAnnot, isShowTableHeader: !selectedAnnot?.isShowTableHeader}
    });
  };

  return (
    <Container>
      <Title>Properties</Title>
      <PropertyBox>
        <PropertyComponent selectedAnnot={selectedAnnot} onClickAddTagBtn={() => setIsShowTagSelector(true)}>
          <AnnotationName
            selectedAnnot={selectedAnnot}
            editTarget={editTarget}
            onChangeEditTarget={onChangeEditTarget}
          />
        </PropertyComponent>

        {type !== 'port' && (
          <>
            <div style={{display: 'flex'}}>
              <PropertyRow rowHeader="Data Values">
                <SwitchForm
                  name="Data Values"
                  id="Data Values"
                  checked={selectedAnnot?.isTableVisible}
                  disabled={!(selectedAnnot?.metaPfdTagInfo?.length > 0)}
                  onChange={onChangeDataValues}
                />
              </PropertyRow>
              <PropertyRow rowHeader="Show Header">
                <SwitchForm
                  name="Table headers"
                  id="Table headers"
                  checked={selectedAnnot?.isShowTableHeader}
                  disabled={!(selectedAnnot?.metaPfdTagInfo?.length > 0)}
                  onChange={onChangeShowTableHeader}
                />
              </PropertyRow>
            </div>

            <div style={{display: 'flex'}}>
              <PropertyRow rowHeader="Time Series">
                <SwitchForm
                  name="Time Series"
                  id="Time Series"
                  checked={selectedAnnot?.isNormalChartVisible}
                  disabled={!(selectedAnnot?.metaPfdTagInfo?.length > 0)}
                  onChange={onChangeIsChartVisible}
                />
              </PropertyRow>
            </div>
            <PropertyTable selectedAnnot={selectedAnnot} dispatchToReducer={dispatchToReducer} />
          </>
        )}

        {isShowTagSelector && (
          <NodeSelectorRevision
            onClose={() => setIsShowTagSelector(false)}
            onSelect={onSelectTags}
            selectedNodes={selectedNodes}
          />
          // <NodeSelector
          //   selectedTags={selectedAnnot?.tagInfo?.hierarchyInfo}
          //   categoryList={type === 'expanding-line' ? ['Model'] : (['Model', 'Plant'] as TreeCategory[])}
          //   limit={type === 'point' ? 2 : 2000}
          //   onSelect={onSelectTags}
          //   beforeOnCheck={beforeOnCheck}
          //   onClose={() => setIsShowTagSelector(false)}
          // />
        )}
      </PropertyBox>
    </Container>
  );
}

export default Properties;
