import React, {ForwardedRef, forwardRef, PropsWithChildren, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {ProcessImageViewer} from 'components/mpfd/ProcessImageViewer';
import {CommonContext} from 'components/common/CommonProvider';
import InfoPanelLayout from 'components/mpfd/InfoPanelLayout';
import {faArrowProgress} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useMPfdKeyBoardEvent from 'hooks/useMPfdKeyBoardEvent';
import useApi from 'api/useApi';
import {IFile} from 'api/data-types';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #e7e3e4;
  display: flex;
  box-sizing: border-box;
  user-select: none;
  img {
    -webkit-user-drag: none;
  }
`;
const CenteredContainer = styled.div`
  width: 300px;
  background-color: #fff;
  margin: auto;
  padding: 20px;
  text-align: center;
  color: #aaa;
  border-radius: 5px;
  > svg {
    margin-right: 10px;
  }
`;

const Stage = forwardRef(function StageLayout(
  props: PropsWithChildren,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const {file, state, dispatchToReducer, onChangeDetectProgress} = useContext(MetaPfdContext);
  const {showSpinner, hideSpinner} = useContext(CommonContext);
  const [imgLoadStatus, setImgLoadStatus] = useState('');
  const api = useApi();
  useMPfdKeyBoardEvent();

  const initializeImageFile = async (file: IFile) => {
    if (!file) return;
    const getImageDimensions = (src: string): Promise<{width: number; height: number}> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
          resolve({width: image.width, height: image.height});
        };
        image.onerror = reject;
      });
    };

    if (file?.localInfo?.uploadImageDataUrl) {
      const extension = file?.localInfo?.uploadImageFile.name.match(/\.([^.]+)$/);
      const fileType = extension ? extension[1] : undefined;
      const {width, height} = await getImageDimensions(file?.localInfo?.uploadImageDataUrl);
      return {
        type: 'SET_IMAGE_INFO',
        images: {
          imageSrc: file?.localInfo?.uploadImageDataUrl,
          imgSourceType: 'upload',
          imgFile: file?.localInfo?.uploadImageFile,
          imgExtension: fileType,
          naturalWidth: width,
          naturalHeight: height
        }
      };
    }

    /**
     * 추후 pid_extension 은 제공되지 않을 예정이므로 default_image / upload_image 가져오는 케이스 분기 필요
     */
    if (file?.pid_extension) {
      const imgBlob = (await api.getImgBlob('/file_manage/get_mpfd_img/upload/' + file._id)) as Blob;
      const imageSrc = URL.createObjectURL(imgBlob);
      const {width, height} = await getImageDimensions(imageSrc);
      const newImgData = {
        imgBlob,
        imageSrc,
        imgSourceType: 'upload',
        fileName: file._id + '.' + file.pid_extension,
        imgExtension: file?.pid_extension,
        naturalWidth: width,
        naturalHeight: height
      };
      return {type: 'SET_IMAGE_INFO', images: newImgData};
    }

    let pidName = file.pidName;
    if (!pidName) return undefined;

    const imgBlob = (await api.getImgBlob('/file_manage/get_mpfd_img/default/' + pidName)) as Blob;
    if (!imgBlob) {
      return null;
    }
    const imageSrc = URL.createObjectURL(imgBlob);
    const extension = pidName.match(/\.([^.]+)$/);
    const fileType = extension ? extension[1] : undefined;

    const {width, height} = await getImageDimensions(imageSrc);

    const newImgData = {
      fileName: pidName,
      imgSourceType: 'upload',
      pidName,
      imgBlob,
      imageSrc,
      imgExtension: fileType,
      naturalWidth: width,
      naturalHeight: height
    };
    return {type: 'SET_IMAGE_INFO', images: newImgData};
  };

  useEffect(() => {
    setImgLoadStatus('');
    onChangeDetectProgress(false);
    showSpinner({});
    initializeImageFile(file).then(function (res) {
      if (res) {
        dispatchToReducer(res);
      } else {
        setImgLoadStatus('fail');
      }
      hideSpinner();
    });
  }, [file]);

  if (file && state.images.imageSrc) {
    return (
      <Container className="stage" ref={ref}>
        <ProcessImageViewer />
        <InfoPanelLayout />
      </Container>
    );
  } else if (imgLoadStatus === 'fail') {
    return (
      <Container className="stage" ref={ref}>
        <CenteredContainer>
          <FontAwesomeIcon icon={faArrowProgress} size="lg" />
          No PFD file.
        </CenteredContainer>
      </Container>
    );
  } else {
    return <Container className="stage" ref={ref} />;
  }
});

Stage.displayName = 'Stage';
export default Stage;
