import {ChangeEvent, ReactElement, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;

  > input[type='color'] {
    width: 100%;
    height: 33px;
    padding: 0;
    border: none;
    flex: 1;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
    }
  }
`;

type IProps = {
  flattenKeys: string;
  color: string;
  onChange(e: ChangeEvent): void;
};

/**
 * DefaultTable 내부에서 색상을 선택
 * @param flattenKeys
 * @param color
 * @param onChange
 * @constructor
 */
function ColorPicker({flattenKeys, color, onChange}: IProps): ReactElement {
  return (
    <Container>
      <input type="color" name={flattenKeys} title="color" value={color} onChange={onChange} />
    </Container>
  );
}

export default ColorPicker;
