import {ReactElement, useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import {MessageSpinner} from 'components/common/index';

function LoadingSpinner(): ReactElement {
  const {spinner} = useContext(CommonContext);

  return <MessageSpinner isShow={Boolean(spinner)} />;
}

export default LoadingSpinner;
