import {Html} from '@react-three/drei';
import annotationsData from 'components/pc/widgets/three/constants/annotationsData.json';
import ThreeLandscapeLabel from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabel';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {useEffect} from 'react';
import {IWidgetMetaData} from 'components/pc/types';
import {IManualCamera, ISelectedLabelInfo} from 'components/pc/widgets/ThreeLandscapeWidget';
import * as THREE from 'three';
import {Vector3Like} from 'three';
import {useThree} from '@react-three/fiber';
import {INodeSubNode} from 'api/data-types';

type IProps = {
  labelsVisible: boolean;
  streamList: INodeSubNode[];
  blockList: INodeSubNode[];
  moveCamera(position: Vector3Like, camera: IManualCamera, scene: THREE.Scene, targetSize?: 'big' | 'small'): void;
  setIsShowNodeSelector(isShowNodeSelector: boolean): void;
  // TODO: selectedLabel, setSelectedLabel 한 번에 받기 (context 등 사용)
  selectedLabel?: ISelectedLabelInfo;
  setSelectedLabel?(labelInfo: ISelectedLabelInfo): void;
};

function ThreeLandscapeLabelsWrapper({
  labelsVisible,
  streamList,
  blockList,
  moveCamera,
  setIsShowNodeSelector,
  selectedLabel,
  setSelectedLabel
}: IProps) {
  const {camera, scene} = useThree();

  useEffect(() => {
    if (selectedLabel?.labelPosition && selectedLabel?.leftMenuClicked) {
      moveCamera(selectedLabel.labelPosition as Vector3Like, camera, scene, 'small');
    }
  }, [selectedLabel]);

  const [createContextMenu] = useContextMenu();
  const {addWidget} = useProcessCanvasCommand();

  const onClickThreeLandscapeLabelsWrapper = (event, info: any): void => {
    event.stopPropagation();

    const selectedLabelInfo: ISelectedLabelInfo = {
      labelName: info.title,
      labelType: info.labelType === 'streams' ? 'streams' : info.labelType === 'equipment' ? 'blocks' : 'undefined',
      labelPosition: {
        x: info.position[0],
        y: info.position[1],
        z: info.position[2]
      },
      clickedPosition: {
        x: event.clientX,
        y: event.clientY
      }
    };
    setSelectedLabel(selectedLabelInfo);

    if (event.button === 2) {
      switch (info.labelType) {
        case 'streams': {
          const metaData: IWidgetMetaData = {
            list: [
              {
                stream: info.title
              }
            ],
            descriptions: null,
            config: {
              autoUpdate: true,
              updateIntervalUnit: 10,
              updateIntervalVal: '10'
            },
            isInitialized: true
          };
          const list = [
            {
              label: 'View Stream Detail',
              value: 'move-camera',
              callback() {
                moveCamera(selectedLabelInfo.labelPosition as Vector3Like, camera, scene);
              }
            },
            {
              label: 'Launch HMB Table',
              value: 'open-hmb-widget',
              callback() {
                // TODO: addWidget 함수를 아래의 equipment처럼 ThreeLandscapeWidget에서 호출하는 방법 생각하기
                addWidget(
                  'hmb',
                  selectedLabelInfo.clickedPosition.x + 50,
                  selectedLabelInfo.clickedPosition.y + 50,
                  info.title,
                  metaData || null
                );
              }
            }
          ];
          createContextMenu({list, event, title: info.title || 'Undefined Label'});
          break;
        }
        case 'equipment': {
          const list = [
            {
              label: 'View Block Detail',
              value: 'move-camera',
              callback() {
                moveCamera(selectedLabelInfo.labelPosition as Vector3Like, camera, scene);
              }
            },
            {
              label: 'Launch Datasheet',
              value: 'open-datasheet-widget',
              callback() {
                setIsShowNodeSelector(true);
              }
            }
          ];
          createContextMenu({list, event, title: info.title || 'Undefined Label'});
          break;
        }
      }
    }
  };

  // TODO: 공통부분 filter하는 logic을 외부에서 처리하도록 수정
  const filterAnnotationsData = (originalList: any[]) => {
    const streamTitles = streamList.map((stream) => stream.name);
    const blockTitles = blockList.map((block) => block.name);
    return originalList.filter(
      (item) =>
        (item.labelType === 'streams' && streamTitles.includes(item.title)) ||
        (item.labelType === 'equipment' && blockTitles.includes(item.title))
    );
  };

  return (
    <>
      {filterAnnotationsData(annotationsData).map((item, i /* Annotations HERE */) => (
        <Html
          key={i}
          position={[item.position[0] / 5, item.position[1] / 5, item.position[2] / 5]}
          style={{cursor: 'pointer'}}
        >
          <div
            onClick={(e) => onClickThreeLandscapeLabelsWrapper(e, item)}
            onContextMenu={(e) => onClickThreeLandscapeLabelsWrapper(e, item)}
          >
            <ThreeLandscapeLabel
              title={item.title}
              description={item.description}
              visible={labelsVisible}
              type={item.labelType}
              selected={selectedLabel && selectedLabel.labelName === item.title}
            />
          </div>
        </Html>
      ))}
    </>
  );
}

export default ThreeLandscapeLabelsWrapper;
