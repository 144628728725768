import {ChangeEvent, PropsWithChildren, ReactElement, useContext, useEffect, useState} from 'react';
import {IChartStackInfo, ISeries, ITagTableData, ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';
import DefaultTable, {ITableField} from 'components/table/DefaultTable';
import {ColorPicker, NodeName} from 'components/table/parts';
import styled from 'styled-components';
import {ITagNameMenuAction} from 'components/table/parts/NodeName';
import {getDataType} from 'components/pc/widgets/timeseries/timeseries-functions';
import {toDateFormat} from 'utils/commons';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';
import ActiveCheckbox from 'components/table/parts/ActiveCheckbox';
import ParallelSelector from 'components/table/parts/ParallelSelector';

const Container = styled.div`
  height: calc(100% - 6px);
`;

const fields: ITableField[] = [
  {
    key: 'active',
    style: {header: {width: 30}, body: {width: 30}},
    css: {body: 'checkbox'},
    component: ActiveCheckbox
  },
  {
    key: 'color',
    style: {header: {width: 30}, body: {width: 30}},
    css: {body: 'no-padding'},
    component: ColorPicker
  },
  {
    key: 'name',
    label: 'Node Name',
    style: {header: {width: 250}, body: {width: 250}},
    component: NodeName
  },
  {
    key: 'stack',
    label: 'Y Axis',
    style: {header: {width: 80}, body: {width: 80}},
    component: ParallelSelector
  },
  {
    key: 'avg',
    label: 'Avg.',
    toFixed: 2,
    style: {header: {width: 100}, body: {width: 100, textAlign: 'right'}}
  },
  {
    key: 'max',
    label: 'Max',
    toFixed: 2,
    style: {header: {width: 100}, body: {width: 100, textAlign: 'right'}}
  },
  {
    key: 'min',
    label: 'Min',
    toFixed: 2,
    style: {header: {width: 100}, body: {width: 100, textAlign: 'right'}}
  },
  /*{
    key: 'top',
    label: 'Top',
    style: {header: {width: 80}},
    component: InputMinMaxValue
  },
  {
    key: 'bottom',
    label: 'Bottom',
    style: {header: {width: 80}},
    component: InputMinMaxValue
  },*/
  {
    key: 'description',
    label: 'Description',
    // style: {body: {width: 200}},
    css: {body: 'ellipsis'}
  }
];

type IProps = PropsWithChildren & {
  loader: ITimeSeriesLoader;
};

function TimeSeriesTable({children, loader}: IProps): ReactElement {
  const [tableData, setTableData] = useState<ITagTableData[]>([]);
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  // const selectedRowState = useState<string[]>([]);

  const convert = (item: ISeries, valueKey: keyof ISeries): number | string | undefined => {
    const value = item?.[valueKey];
    if (!value) return undefined;
    if (getDataType(item.keys) === 'datetime') {
      return toDateFormat(Number(value) * 1000, 'HH:mm:ss');
    } else {
      return toSignificantDigit(Number(value), globalSettings.significantDigit);
    }
  };

  /**
   * row 중 td 에 해당하는 정보(row data)가 변경됨
   * @param e
   */
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log('onChange', e.target.value);
    const {value, title, name, checked} = e.target;
    // console.log('TimeSeriesTable>>', title, name, value, checked, e);
    const {series, setStackInfo, refreshSeries} = loader;

    const found = series.find((item) => item.flattenKeys === name);
    // console.log('TimeSeriesTable>> found', found);
    if (found === undefined) return;

    switch (title) {
      case 'active':
        found.active = checked;
        break;
      case 'parallel':
        found.parallel = Number(value);
        const reduced: IChartStackInfo[] = series.reduce((acc, cur) => {
          if (!acc.some((info) => info.parallel === cur.parallel)) {
            acc.push({parallel: cur.parallel, stacked: true, checked: true});
          }
          return acc;
        }, []);
        setStackInfo(reduced);
        break;
      case 'color':
        found.color = value;
        break;
      case 'top':
        found.top = Number(value);
        break;
      case 'bottom':
        found.bottom = Number(value);
        break;
      default:
        // case 가 없으면 변경이 없는 것으로 간주하고 바로 중단. 즉 updateSeries() 를 호출하지 않음
        return;
    }
    refreshSeries();
  };

  const onMenu = (action: ITagNameMenuAction): void => {
    console.log(action.type, action);
    switch (action.type) {
      case 'CHANGE':
        // setIsShowNodeSelector(true);
        break;
      case 'REMOVE':
        // setSelectedTags(selectedTags.filter((tag) => tag.join('-') !== action.keys.join('-')));
        // setSelectedNodes(selectedNodes.filter((node) => node.join('-') !== action.keys.join('-')));
        // removeSeries(action.keys);
        break;
      default:
      // do nothing...
    }
  };

  // series 가 변경되면 DefaultTable 에서 표시할 수 있도록 형태롤 만들어 props 로 전단한다.
  useEffect(() => {
    const refined: ITagTableData[] = loader.series?.map((item, index) => {
      // const sorted = getSortedNodes(item.data, item.keys);
      return {
        ...item,
        flattenKeys: item.keys.join('-'),
        avg: convert(item, 'avg'),
        min: convert(item, 'min'),
        max: convert(item, 'max'),
        top: undefined,
        bottom: undefined,
        // description: undefined,
        // active: item.active || true,
        // stack: item.stack === undefined ? index : item.stack,
        onChange,
        onMenu
      };
    });
    // const [, setSelectedRows] = selectedRowState;
    // 선택상태는 초기값이 모두 선택
    // setSelectedRows(refined.map((item) => item.flattenKeys));
    // 데이터 SET
    setTableData(refined);
  }, [loader.series]);

  const onReorder = (list: ITagTableData[]): void => {
    // console.log('>>>> onsort', list);
    setTableData(list);
    loader.reorder(list.map((row) => row.flattenKeys));
  };

  return (
    <Container className="asdfasdf">
      <DefaultTable
        fields={fields}
        fieldKey="key"
        fieldLabelKey="label"
        rows={tableData}
        rowKey="flattenKeys"
        // selectedRowState={selectedRowState}
        onReorder={onReorder}
      >
        {children}
      </DefaultTable>
    </Container>
  );
}

export default TimeSeriesTable;
