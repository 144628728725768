import {ForwardedRef, forwardRef, PropsWithChildren, ReactElement, MouseEvent, useState} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div<{$type: 'horizontal' | 'vertical'; $color?: string; $stroke: number}>`
  cursor: ${({$type}) => ($type === 'horizontal' ? 'ns-resize' : 'ew-resize')};
  width: ${({$type, $stroke}) => ($type === 'horizontal' ? '100%' : `${$stroke}px`)};
  height: ${({$type}) => ($type === 'horizontal' ? '6px' : '100%')};
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: ${({$type}) => ($type === 'horizontal' ? 'row' : 'column')};
  flex-shrink: 0;
  justify-content: center;
  background-color: ${({$color}) => $color};
  user-select: none;

  &:after {
    width: inherit;
    height: inherit;
    min-width: 10px;
    min-height: 10px;
    content: '';
  }

  &:hover {
    background-color: #b4edff;
  }
  &.dragging {
    background-color: #4be4ff;
  }
`;

const Handle = styled.div<{$type: 'horizontal' | 'vertical'; $stroke: number}>`
  width: ${({$type, $stroke}) => ($type === 'horizontal' ? 50 : $stroke + 4)}px;
  height: ${({$type, $stroke}) => ($type === 'horizontal' ? $stroke + 4 : 50)}px;
  background-color: #e7e7e7;
  flex-direction: ${({$type}) => ($type === 'horizontal' ? 'column' : 'row')};
  position: absolute;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 1px 10px rgba(0, 0, 0, 0.1),
    0 0 2px rgba(0, 0, 0, 0.6);
  user-select: none;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    height: ${({$type}) => ($type === 'horizontal' ? '1px' : '90%')};
    width: ${({$type}) => ($type === 'horizontal' ? '90%' : '1px')};
    flex-shrink: 0;
    background-color: #d0cece;
  }
`;

type IProps = PropsWithChildren & {
  type?: 'horizontal' | 'vertical';
  color?: string;
  stroke?: number;
  hasHandle?: boolean;
  onMouseDown?(e: MouseEvent): void;
};

const ResizeHandle = forwardRef(function ResizeHandleLayout(
  {children, type = 'horizontal', color = '#ffffff', stroke = 6, hasHandle = true, onMouseDown}: IProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDownContainer = (e: MouseEvent) => {
    setIsDragging(true);
    onMouseDown?.(e);
  };

  const onMouseUpContainer = (): void => {
    setIsDragging(false);
  };

  return (
    <Container
      ref={ref}
      $type={type}
      $color={color}
      $stroke={stroke}
      className={classNames({dragging: isDragging}, type)}
      onMouseDown={onMouseDownContainer}
      onMouseUp={onMouseUpContainer}
    >
      {children}
      {hasHandle && <Handle $type={type} $stroke={stroke} />}
    </Container>
  );
});

ResizeHandle.displayName = 'ResizeHandle';
export default ResizeHandle;
