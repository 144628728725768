import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import {useEffect, useRef, useState} from 'react';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import ComponentTable from 'components/table/ComponentTable';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import BasicSpinner from 'components/common/BasicSpinner';
import SignificantValue from 'components/table/parts/SignificantValue';
import {ILatestNodeHandlerReturn} from 'hooks/useLatestNodeHandler';
import classnames from 'classnames';

type StyleProps = {
  $isLiveUpdate: boolean;
};

const Container = styled.div<StyleProps>`
  height: calc(100%);
  position: relative;

  .live {
    animation: blinkAnimation 0.8s ease-in-out 3;
    animation-fill-mode: forwards;
  }

  @keyframes blinkAnimation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

const LiveDisplay = styled.div`
  position: absolute;
  top: 8px;
  right: 15px;
  z-index: 2;
  width: 10px;
  height: 10px;
  border-radius: 2.5px;
  background-color: #00a6cb;
  opacity: 0;
  &.live {
    opacity: 1;
  }
`;

const LoadingContainer = styled.div`
  background-color: white;
  border: 1px solid #a5a5a5;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type IProps = {
  iw: number;
  ih: number;
  mat: any;
  cfg: IPfdCfg;
  latestTagHandler?: ILatestTagHandlerReturn;
  latestNodeHandler?: ILatestNodeHandlerReturn;
  region: IAnnotation;
  id?: string;
};

function MetaPfdDataTable({region, id, cfg, latestTagHandler, latestNodeHandler}: IProps) {
  const [rows, setRows] = useState([]);
  const {latestResult, renewSubscribe, disconnectSubscribe} = latestNodeHandler;
  const prevLatestResult = useRef(latestResult[id]);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const fnc = () => {
      const tags = region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item?.tag);
      const nodeInfos = tags?.map((tag) => {
        const [database, ...hierarchy] = tag;
        return {
          database,
          name: hierarchy.pop(),
          hierarchy
        };
      });
      renewSubscribe(id, nodeInfos, cfg?.isLiveUpdateDataValues);
      // renewSubscribe(id, nodeInfos, true);
    };
    timer = setTimeout(() => fnc(), 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [region.metaPfdTagInfo, id, cfg?.isLiveUpdateDataValues]);

  useEffect(() => {
    const res = latestResult[id];
    //:todo object 검사하여 동일하면 return
    if (res) {
      const tags = region.metaPfdTagInfo
        .filter((item) => item.isTableDisplay)
        .map((item) => {
          const [category, ...rest] = item.tag;
          return [category.toLowerCase(), ...rest].join(', ');
        });
      const newRows = res
        .filter((item) => tags.includes(item.key))
        .map((item) => ({
          tagName: item?.key,
          value: item.value?.[0]?.[1]
        }));
      setRows(newRows);
    }
  }, [latestResult, id, region.metaPfdTagInfo]);

  useEffect(() => {
    if (latestResult && cfg?.isLiveUpdateDataValues) {
      const prevResult = prevLatestResult.current;
      const currentResult = latestResult[id];

      if (JSON.stringify(prevResult) !== JSON.stringify(currentResult)) {
        setIsLive(false);
        setTimeout(() => {
          setIsLive(true);
          const timer = setTimeout(() => {
            setIsLive(false);
          }, 2000);
          return () => clearTimeout(timer);
        }, 0);
      }

      prevLatestResult.current = currentResult;
    }
  }, [latestResult, cfg?.isLiveUpdateDataValues]);

  useEffect(() => {
    return () => {
      disconnectSubscribe(id);
    };
  }, []);

  const cellHeight = 16;
  const cellFontSize = 20;

  const fields: ITableField[] = [
    {
      key: 'tagName',
      label: 'Tag Name',
      style: {
        header: {
          width: '60%',
          height: cellHeight,
          fontSize: cellFontSize,
          boxSizing: 'border-box',
          display: !region?.isShowTableHeader && 'none'
        },
        body: {fontSize: cellFontSize, height: cellHeight, boxSizing: 'border-box'}
      },
      css: {body: 'ellipsis'}
    },
    {
      key: 'value',
      label: 'Value',
      style: {
        header: {
          width: '40%',
          height: cellHeight,
          fontSize: cellFontSize,
          boxSizing: 'border-box',
          display: !region?.isShowTableHeader && 'none'
        },
        body: {textAlign: 'right', fontSize: cellFontSize, height: cellHeight, boxSizing: 'border-box'}
      },
      css: {body: 'ellipsis'},
      component: SignificantValue
    }
  ];
  return (
    <Container $isLiveUpdate={cfg?.isLiveUpdateDataValues}>
      {cfg?.isLiveUpdateDataValues && <LiveDisplay className={classnames(isLive && 'live')} />}
      {rows?.length > 0 && (
        <ComponentTable
          fields={fields}
          rows={rows}
          rowKey="tagName"
          headerCssProperties={{display: !region?.isShowTableHeader && 'none'}}
        />
      )}
      {rows?.length === 0 &&
        region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item.tag).length > 0 && (
          <LoadingContainer>
            <BasicSpinner isShow={true} type="default" position="center-center" size="md" />
          </LoadingContainer>
        )}
    </Container>
  );
}

export default MetaPfdDataTable;
