import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {IAppMenu} from 'components/menu/types';
import AppMenuItem from 'components/menu/AppMenuItem';
import {ActionMenu} from 'components/menu/action';
import {Link, useLocation} from 'react-router-dom';
import {managementMenuList, mpfdMenuList, pcMenuList} from 'components/menu/constants';
import UtilMenu from 'components/menu/UtilMenu';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import logo from 'assets/images/simacro/simacro-logo.svg';

const Container = styled.aside`
  //background-color: #1c1c1c;
  background: linear-gradient(#342350, #1c1c1c 50%);
  width: ${({theme}) => theme.app.menu.width}px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
  z-index: 100; // 추후 zIndex 전부 확인필요

  &.meta-pfd {
    background-color: #130e1c;

    .app-menu {
      .app-menu-item {
        &.active {
          background-color: #1c1628;
        }
      }
    }
  }

  .app-menu {
    width: ${({theme}) => theme.app.menu.width}px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-top: 58px;

    .app-menu-item {
      //margin-bottom: 10px;
      padding: 12px 0;
      cursor: pointer;

      &.active {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .menu-label {
        margin-top: 5px;
      }
    }
  }
`;

const bg = '#16003a';
const Logo = styled.div`
  background-color: ${bg};
  width: 100%;
  height: 130px;
  position: relative;

  &:after {
    content: '';
    width: 60px;
    height: ${({theme}) => theme.app.menu.width}px;
    background-color: ${bg};
    display: block;
    transform: rotate(45deg);
    border-radius: 10px;
    position: absolute;
    top: 100px;
  }

  a {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
  }
`;

const AppName = styled.div`
  position: relative;
  z-index: 2;
  color: #ee5c84;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
`;

type IRedirectInfo = {
  pc?: string;
  mpfd?: string;
};

type IAppName = {
  name: 'Process Canvas' | 'Meta PFD Editor' | undefined;
  type: 'process-canvas' | 'meta-pfd' | undefined;
};

function AppMenu(): ReactElement {
  const {remoteAppMenu, showActionMenu} = useContext(CommonContext);
  const pcContext = useContext(ProcessCanvasContext);
  const mpfdContext = useContext(MetaPfdContext);
  const [appMenuList, setAppMenuList] = useState<IAppMenu[]>([]);
  const [app, setApp] = useState<IAppName>({name: undefined, type: undefined});
  // const [selected, setSelected] = useState<IAppMenu>();
  const location = useLocation();
  // route 에 전달할 상태 객체. 현재는 canvas 와 mpfd editor 의 file 정보를 유지하는데 사용함
  const fileState: IRedirectInfo = {pc: pcContext?.file?._id, mpfd: mpfdContext?.file?._id};

  /**
   * App 메뉴 (대메뉴) 기능 선택
   * @param menuData
   */
  const onSelectAppMenu = (menuData: IAppMenu): void => {
    const bool = menuData.id === remoteAppMenu?.id;
    // setSelected(bool ? undefined : menuData);
    showActionMenu(bool ? undefined : menuData);
    //접을 때는 provider 를 리셋 시킴
    // if (bool) {
    //   showActionMenu(null);
    // }
  };

  useEffect(() => {
    // setSelected(undefined);
    showActionMenu(undefined);
    setApp(() => {
      if (location.pathname.startsWith('/pc')) {
        setAppMenuList(pcMenuList);
        return {name: 'Process Canvas', type: 'process-canvas'};
      } else if (location.pathname.startsWith('/mpfd')) {
        setAppMenuList(mpfdMenuList);
        return {name: 'Meta PFD Editor', type: 'meta-pfd'};
      } else if (location.pathname.startsWith('/manage')) {
        setAppMenuList(managementMenuList);
        return {name: 'Meta PFD Editor', type: 'meta-pfd'};
      }
    });
  }, [location]);

  // root 일 경우 이 메뉴 컴포넌트를 표시하지 않음
  if (location.pathname === '/') {
    return undefined;
  }

  /**
   * Meta PFD Mode 초기화 (Move Point / Region 일때 캔버스 이외의 영역에서 Mouse UP을 했을때 정상적으로 작동하지 않는 문제
   * Info Box / App Menu / Tool box ( Canvas 보다 위에 있는 것들 ) 에 event 할당
   */
  const onMouseUp = () => {
    if (app?.type === 'meta-pfd') {
      mpfdContext.dispatchToReducer({type: 'onMouseUpOnOutOfCanvas'});
    }
  };

  return (
    <Container className={app?.type} onMouseUp={onMouseUp}>
      <Logo>
        <Link to="/" state={fileState}>
          <img src={logo} alt="Company Logo" width={38} />
        </Link>
        <AppName>{app?.name}</AppName>
      </Logo>
      <ul className="app-menu">
        {appMenuList.map((menu) => (
          <AppMenuItem key={menu.id} data={menu} isActive={remoteAppMenu?.id === menu.id} onSelect={onSelectAppMenu} />
        ))}
      </ul>
      <ActionMenu appMenu={remoteAppMenu} />
      <UtilMenu />
    </Container>
  );
}

export default AppMenu;
