import {ReactElement, useContext, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faArrowProgress, faDiagramProject, faFile} from '@fortawesome/pro-light-svg-icons';
import {faLock} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import {IFile} from 'api/data-types';
import Tooltip from 'components/common/Tooltip';
import classNames from 'classnames';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {MetaPfdContext} from 'components/mpfd';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 300px;
  height: 100%;

  .fa-layers {
    position: relative;
    display: block;
    margin-left: 20px;
    width: 30px;
    height: 100%;

    .lock-icon {
      position: absolute;
      margin-bottom: 6px;
      margin-left: 0;
    }
  }
`;

const NameText = styled.div`
  width: 250px;
  //color: rgba(255, 255, 255, 0.8);
  color: #f1a0a0;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  flex: 1;
  //height: 40px;
  box-sizing: border-box;

  &.owner {
    color: #83efbe;
  }

  &.show {
    background-color: rgba(39, 37, 70, 0.8);
    white-space: normal;
    height: auto;
  }

  &.untitled {
    color: rgba(255, 255, 255, 0.4);
    font-style: italic;
    font-weight: initial;
  }
`;

const TooltipContent = styled.div`
  .owner {
    font-weight: 700;
  }
`;

type IProps = {
  file: IFile;
  type: 'process-canvas' | 'mpfd';
  isPrivate?: boolean;
  isFileOwner?: boolean;
};

function FileInfoDisplay({file, type, isPrivate, isFileOwner}: IProps): ReactElement {
  const pc = useContext(ProcessCanvasContext);
  const mpfd = useContext(MetaPfdContext);

  const [isPcTransferOwner] = pc.isTransferOwnerState;
  const [isPcNewName] = pc.isNewNameState;
  const [socketPcMessage] = pc.socketMessageState;
  const [isMPfdTransferOwner] = mpfd.isTransferOwnerState;
  const [isMPfdNewName] = mpfd.isNewNameState;
  const [socketMPfdMessage] = mpfd.socketMessageState;
  const [isShow, setShow] = useState(false);

  const getFileIcon = (): IconDefinition => {
    switch (type) {
      case 'process-canvas':
        return faDiagramProject;
      case 'mpfd':
        return faArrowProgress;
      default:
        return faFile;
    }
  };

  const ownershipType = () => {
    switch (type) {
      case 'process-canvas':
        return isPcTransferOwner;
      case 'mpfd':
        return isMPfdTransferOwner;
      default:
        return;
    }
  };

  const prevOwnerType = () => {
    switch (type) {
      case 'process-canvas':
        return pc.prevOwner;
      case 'mpfd':
        return mpfd.prevOwner;
      default:
        return;
    }
  };

  const newNameType = () => {
    switch (type) {
      case 'process-canvas':
        return isPcNewName;
      case 'mpfd':
        return isMPfdNewName;
      default:
        return;
    }
  };

  return (
    <Container onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <span className="fa-layers">
        <FontAwesomeIcon icon={getFileIcon()} size="lg" color="rgba(255, 255, 255, 0.8)" />
        {isPrivate && (
          <Tooltip content={`${file.owner} is the owner of this file.`} offset={35} place="bottom-start">
            <FontAwesomeIcon icon={faLock} className="lock-icon" size="sm" color="#f1a0a0" />
          </Tooltip>
        )}
        {ownershipType() && (
          <Tooltip
            type="notice"
            isOpen={ownershipType()}
            content={
              <TooltipContent>
                The ownership of this file has been transferred from <span className="owner">{prevOwnerType()}</span> to
                you
              </TooltipContent>
            }
            offset={35}
            place="bottom-start"
          ></Tooltip>
        )}
        {newNameType() && (
          <Tooltip
            type="notice"
            isOpen={newNameType()}
            content={
              <TooltipContent>
                This File was renamed by <span className="owner">{prevOwnerType()}</span>
              </TooltipContent>
            }
            offset={35}
            place="bottom-start"
          ></Tooltip>
        )}
      </span>
      <NameText className={classNames({untitled: file?.fileName === undefined}, {show: isShow}, {owner: isFileOwner})}>
        {file?.fileName || 'Untitled File'}
      </NameText>
    </Container>
  );
}

export default FileInfoDisplay;
