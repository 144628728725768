import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {NodeProps} from 'reactflow';
import {IPythonEditorLogWidgetData, IWidgetNodeData} from 'components/pc/types';
import React, {useContext, useEffect, useState} from 'react';
import {IPythonEditorWidgetSubjectInfoDetail, LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {faFileLines} from '@fortawesome/pro-thin-svg-icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {HotTable} from '@handsontable/react';
import TimestampDataRenderer from 'components/spreadsheet/renderer/TimestampDataRenderer';
import {IPythonEditorSimulationResult} from 'components/pc/widgets/pythonEditor/types';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const TableContainer = styled.div`
  height: 100%;
`;

function PythonEditorSimulationResultWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>) {
  const {contentInfo} = useContext(LocalDatabaseContext);
  const [connectedPythonEditorSubjectInfo, setConnectedPythonEditorSubjectInfo] =
    useState<IPythonEditorWidgetSubjectInfoDetail>();

  useEffect(() => {
    const targetId = (data.metaData as IPythonEditorLogWidgetData).targetPythonEditorId;
    const widgetSubjectInfo = contentInfo?.[targetId];
    if (widgetSubjectInfo?.type === 'PythonEditorWidget') {
      setConnectedPythonEditorSubjectInfo(widgetSubjectInfo as IPythonEditorWidgetSubjectInfoDetail);
    }
  }, [contentInfo]);

  const getFormattedTimestamp = (timestamp: number) => {
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
  };

  const simulationHistory = connectedPythonEditorSubjectInfo?.ref?.current?.pythonEditorSimulationResultHistory;

  const generateHandsontableData = (inputData: IPythonEditorSimulationResult[]) => {
    if (!inputData || inputData?.length === 0) {
      return;
    }
    // Collect all unique inflow and outflow keys
    const inflowSet = new Set();
    const outflowSet = new Set();

    inputData.forEach((item) => {
      Object.keys(item.variableInfo.inflow).forEach((key) => inflowSet.add(key));
      Object.keys(item.variableInfo.outflow).forEach((key) => outflowSet.add(key));
    });

    const inflowKeys = Array.from(inflowSet);
    const outflowKeys = Array.from(outflowSet);

    const header1 = [
      'timestamp',
      ...(inflowKeys.length > 0 ? ['inflow', ...new Array(inflowKeys.length - 1).fill('')] : []),
      ...(outflowKeys.length > 0 ? ['outflow', ...new Array(outflowKeys.length - 1).fill('')] : [])
    ];
    const header2 = ['', ...inflowKeys, ...outflowKeys];

    // Merge settings
    const mergeCells = [
      {row: 0, col: 0, rowspan: 2, colspan: 1},
      {row: 0, col: 1, rowspan: 1, colspan: inflowKeys.length},
      {row: 0, col: 1 + inflowKeys.length, rowspan: 1, colspan: outflowKeys.length}
    ];

    // Data rows
    const data = inputData.map((item) => {
      const row = [
        item.timestamp,
        ...inflowKeys.map((key) => item.variableInfo.inflow[key as string] || ''),
        ...outflowKeys.map((key) => item.variableInfo.outflow[key as string] || '')
      ];
      return row;
    });

    // Combine headers and data
    const result = [header1, header2, ...data];

    return {
      data: result,
      mergeCells: mergeCells
    };
  };

  const calcData = generateHandsontableData(simulationHistory);

  const title = data.title === 'Python Editor' || data.title === '' ? 'Untitled' : data.title;

  return (
    <WidgetContainer {...rest} data={data} type="PythonEditorSimulationResultWidget">
      <WidgetHeader
        {...rest}
        type="PythonEditorSimulationResultWidget"
        icon={faFileLines}
        id={id}
        hasMinimized={false}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'PythonEditorWidget', titleData: title, data})}
        suffix="- Python Editor Simulation Results"
        onClose={() => connectedPythonEditorSubjectInfo?.ref?.current?.afterRemovePythonSimulationWidget()}
      />
      <WidgetBody>
        <TableContainer>
          <HotTable
            height="100%"
            width="100%"
            colWidths={(index) => {
              if (index === 0) {
                return 100;
              } else {
                return 80;
              }
            }}
            rowHeaders={true}
            colHeaders={true}
            data={calcData?.data}
            mergeCells={calcData?.mergeCells}
            licenseKey="non-commercial-and-evaluation"
            // readOnly={true}
          >
            <TimestampDataRenderer hot-renderer headerRow={1} timestampCol={0} />
          </HotTable>
        </TableContainer>
      </WidgetBody>
    </WidgetContainer>
  );
}

export default PythonEditorSimulationResultWidget;
