import React, {useContext, useEffect, useState} from 'react';
import {IDataBindingType} from 'components/pc/common/shapes/type';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import styled from 'styled-components';
import TabContentsContainer from 'components/common/tab/TabContentsContainer';
import TabContent from 'components/common/tab/TabContent';
import TagData from 'components/pc/common/shapes/parts/data-binding/TagData';
import Tab from 'components/common/Tab';
import {Button} from 'components/forms';
import Option from 'components/pc/common/shapes/parts/data-binding/Option';
import Display from 'components/pc/common/shapes/parts/data-binding/Display';
import {useReactFlow} from 'reactflow';
import {IShapeData} from 'components/pc/types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 39px;
  .data-binding-tab {
    background-color: white;
    height: 51px !important;
    .tab-item {
      width: 247px;
      height: 42px;
      font-size: 17px;
      font-weight: 400;
      display: flex;
      justify-content: center;
    }
  }
`;

type IDataBinding = {
  type: string;
  label: string;
};

const dataBindingTab: IDataBinding[] = [
  {type: 'tagData', label: 'Tag Data'},
  {type: 'options', label: 'Options'},
  {type: 'liveDisplay', label: 'Live Display'}
];

function ShapeDataBindingModal() {
  const {dataSettingModalState} = useContext(ProcessCanvasContext);
  const [dataSettingTargetNodeId, setDataSettingTargetNodeId] = dataSettingModalState;
  const [copy, setCopy] = useState<IDataBindingType>();
  const [tab, setTab] = useState('tagData');
  const {getNode, setNodes} = useReactFlow();

  useEffect(() => {
    if (!dataSettingTargetNodeId) return;
    const targetNode = getNode(dataSettingTargetNodeId);
    const metaData = targetNode?.data?.metaData as IShapeData;
    if (metaData?.dataBindingType) {
      setCopy(metaData?.dataBindingType);
    }
  }, [dataSettingTargetNodeId]);

  const onCloseModal = () => {
    setDataSettingTargetNodeId(null);
  };

  const onClickTab = (tab: string) => {
    setTab(tab);
  };

  const onChangeCopy = (data: Partial<IDataBindingType>) => {
    setCopy((prev) => ({...prev, ...data}));
  };
  const onClickApply = () => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === dataSettingTargetNodeId ? {...node, data: {...node.data, updateData: copy}} : node
      )
    );
    setDataSettingTargetNodeId(null);
  };

  if (dataSettingTargetNodeId && copy) {
    return (
      <ModalWrapper>
        <ModalHeader onClose={onCloseModal}>Data Binding Setting</ModalHeader>
        <ModalBody>
          <Wrapper className="nodrag nopan">
            <Tab<IDataBinding>
              options={dataBindingTab}
              valueKey="type"
              labelKey="label"
              value={tab}
              className="data-binding-tab"
              onSelect={onClickTab}
            />
            <TabContentsContainer tab={tab}>
              <TabContent height={400} width={770} key="tagData">
                <TagData copy={copy} onChangeCopy={onChangeCopy} />
              </TabContent>
              <TabContent height={400} width={770} key="options">
                <Option copy={copy} onChangeCopy={onChangeCopy} />
              </TabContent>
              <TabContent height={400} width={770} key="liveDisplay">
                <Display copy={copy} onChangeCopy={onChangeCopy} />
              </TabContent>
            </TabContentsContainer>
          </Wrapper>
        </ModalBody>
        <ModalFooter>
          <Button width={121} onClick={onClickApply}>
            Apply
          </Button>
          <Button width={69} variant="cancel" onClick={onCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    );
  }
}

export default ShapeDataBindingModal;
