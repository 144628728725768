import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ModalBody, ModalWrapper} from 'components/common';
import {SelectForm} from 'components/forms';
import ModalFooter from 'components/common/modal/ModalFooter';
import Button from 'components/forms/Button';
import {gltfModelsPaths} from 'components/pc/widgets/three/constants/config';

type IProps = {
  isModelSelected: boolean;
  onCancel(isShowConfig: boolean): void;
  onClick(): void;
  onChangeModelType(value: string): void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 88%;
`;

// todo: mapping 제작 예정
const modelOptions = [
  {company: 'HDO', label: 'FCC', value: 'model_fcc'},
  {company: 'AGC', label: 'VCM', value: 'model_vcm'}
];

const COMPANY_CODE = process.env.REACT_APP_COMPANY;

function ThreeLandscapeSetting({isModelSelected, onCancel, onClick, onChangeModelType}: IProps): ReactElement {
  const [value, setValue] = useState('');

  const noSelectModel = value === '';

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const filtered = modelOptions.filter((option) => option.company === COMPANY_CODE || 'SIMACRO' === COMPANY_CODE);
    setOptions(filtered);
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
  };

  const onClickConfirm = () => {
    onChangeModelType(value);
    onClick();
  };
  const onClickCancel = () => {
    onCancel(false);
  };

  return (
    <Container>
      <ModalBody align="center" size="full-width">
        <SelectForm
          placeholder="Select Model"
          className="model-select"
          name="model-select"
          width="width"
          height={40}
          options={options}
          value={value}
          onChange={onChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button width={100} onClick={onClickConfirm} disabled={noSelectModel}>
          Ok
        </Button>
        <Button variant="cancel" onClick={onClickCancel} disabled={!isModelSelected}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default ThreeLandscapeSetting;
