import {Context, createContext, PropsWithChildren, useContext, useState} from 'react';
import {
  ICopilotDataReturn,
  ICopilotFunctionData,
  ICopilotMessage,
  ICopilotMessageParams,
  ICopilotMessageReturn,
  ICopilotNodeDataParams,
  ICopilotResponse,
  ICopilotReturn,
  ICopilotThread,
  ICopilotThreadReturn,
  IDataRangeReturn,
  RequestType
} from 'components/menu/copilot/types';
import useApi from 'api/useApi';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {ICopilotSettings} from 'components/menu/copilot/CopilotSettingModal';
import {CommonContext} from 'components/common/CommonProvider';
import {AuthContext} from 'components/auth/AuthProvider';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {ContentState, convertToRaw} from 'draft-js';
import {IWidgetMetaData} from 'components/pc/types';

export type ICopilotContext = {
  threadList: ICopilotThread[]; //todo 배열 이름 리스트 제거
  currentThread: ICopilotThread;
  isWaiting: boolean;
  isNewChat: boolean;
  isOn: boolean;
  voiceIsOn: boolean;
  userIsOn: boolean;
  isPlaying: boolean;
  dataList: string[];
  dataRange: string[];
  sendRequest(userId: string, reqType: RequestType, message: ICopilotMessageParams, threadId: string): Promise<any>;
  changeCurrentThread(thread_id: string): void;
  getThreadList(): void;
  setIsOn(value: boolean): void;
  setVoiceIsOn(value: boolean): void;
  setUserIsOn(value: boolean): void;
  setFileId(value: string): void;
  userTypeChange(userIsOn: boolean): void;
  setIsPlaying(value: boolean): void;
  sendQuestion(thread_id: string, message: ICopilotMessage): Promise<any>;
  rename(thread_id: string, newTitle: string): void;
  remove(thread_id: string): void;
  getRawData(thread_id: string): Promise<object[]>;
  changeDataRange(nodeInfos: ICopilotNodeDataParams): void;
  setDataList(dataList: string[]): void;
};

export const CopilotContext: Context<ICopilotContext> = createContext(null);

function CopilotProvider({children}: PropsWithChildren) {
  const api = useApi();
  const {userProfile} = useContext(AuthContext);
  const {showModal} = useContext(CommonContext);
  const {addWidget, copilotAddWidget, copilotRemoveWidget} = useProcessCanvasCommand();
  const [threadList, setThreadList] = useState<ICopilotThread[]>([]);
  const [currentThread, setCurrentThread] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  const settings = LocalStorageManager.getItem<ICopilotSettings>('COPILOT_SETTINGS') || {
    voiceIsOn: true,
    dataIsOn: true,
    userIsOn: true
  };

  const [isOn, setIsOn] = useState(settings.dataIsOn);
  const [voiceIsOn, setVoiceIsOn] = useState(settings.voiceIsOn); // voice 설정 on / off 변경
  const [userIsOn, setUserIsOn] = useState(settings.userIsOn);
  const [fileId, setFileId] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [dataList, setDataList] = useState<string[]>([]);
  const [dataRange, setDataRange] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState<string>();

  let user = userProfile?.username;

  const sendRequest = async (
    user_id: string,
    reqType: RequestType,
    message: ICopilotMessageParams,
    threadId: string
  ) => {
    return api.post('/gpt/send-message', {user_id, req_type: reqType, message, thread_id: threadId});
    /* return new Promise((resolve, reject) => {
      fetch('http://localhost:8000/gpt/send-message', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user_id, req_type: reqType, message, thread_id: threadId})
      })
        .then((res) => res.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });*/
  };

  const changeDataRange = (nodeInfos: ICopilotNodeDataParams) => {
    setCurrentThread(null);
    setIsNewChat(true);

    // 선택한 node와 time_range가 있을 경우 포함해서 thread 생성. 없을 경우 data 없는 상태로 thread 생성됨
    const msg = nodeInfos.node_infos.length === 0 ? {} : {data_info: nodeInfos};
    sendRequest(user, 'new_thread', msg, '').then((res: ICopilotReturn) => {
      if (res === null) {
        let opt = {
          title: 'Copilot',
          content:
            'No data available for the selected time range.\nPlease choose a time range with available data to create a chat.',
          confirmLabel: 'Ok'
        };
        showModal(opt);
        setIsNewChat(false);
      }
      if (res?.status === 'completed') {
        const created = {
          id: res.content.thread_id,
          title: res.content.title,
          chat_history: []
        };

        setCurrentThread(created);
        setThreadList([created, ...threadList] as ICopilotThread[]);
        setIsNewChat(false);
        getThreadList().then((threadList) => {
          const newThread = threadList?.find((item) => item?.id === res.content.thread_id);
          if (newThread) {
            setCurrentThread(newThread);
          }
        });
      } else if (res?.status === 'failed') {
        setIsNewChat(false);
        let opt = {
          title: 'Copilot',
          content: res?.detail ? res?.detail : 'Failed to load new chat data.\n Please check again.',
          confirmLabel: 'Ok'
        };
        showModal(opt);
      }
    });
    // getThreadList();
  };

  const sendQuestion = async (thread_id: string, message: ICopilotMessage) => {
    // 메세지를 전송했을 때 you의 메세지가 먼저 보이도록 변경함
    const found = threadList.find((thread) => thread.id === thread_id);
    const updatedChatHistory = [...found.chat_history, message];
    const updated = {...found, chat_history: updatedChatHistory};
    setIsWaiting(true);
    setCurrentThread(updated);
    // const reqType = isVoiceMode ? 'voice' : 'question';
    const msg = {voice: voiceIsOn, question: message.text} as ICopilotMessageParams;

    return await sendRequest(user, 'question', msg, thread_id).then((res: ICopilotMessageReturn) => {
      setIsWaiting(false);

      const messages = res.content;
      if (!Array.isArray(messages)) {
        let opt = {
          title: 'Copilot',
          content: 'Result fail error has occurred.\nPlease refresh the page.',
          confirmLabel: 'Ok'
        };
        if (res.status !== 'completed') {
          opt.content = 'Api server error has occurred.\nPlease refresh the page and restart the server.';
        }
        showModal(opt);
        throw new Error(JSON.stringify(messages));
      }

      // 리턴에 function 이 있을 경우 widget 생성
      for (let func of res?.function) {
        controlWidget(func?.widget_type, func?.command, func?.data);
      }

      setThreadList((currentThreadList) => {
        return currentThreadList.map((thread) => {
          if (thread.id === thread_id) {
            if (voiceIsOn === true) {
              message.voice = fileId;
            }
            const modifiedMessage = {...message, file_id: fileId}; // todo 내가 보낸 메세지 상태 확인 필요
            const updatedChatHistory = [...thread.chat_history, modifiedMessage, ...messages];
            const updated = {...thread, chat_history: updatedChatHistory};
            setCurrentThread(updated);
            return updated;
          }
          return thread;
        });
      });
    });
  };

  const controlWidget = (type: string, command: string, data: ICopilotFunctionData) => {
    if (command === 'open') {
      switch (type) {
        case 'TimeSeriesWidget':
          const metaData: IWidgetMetaData = {
            nodes: data.node,
            isLive: false,
            range: [new Date(data.start_date).getTime() * 0.001, new Date(data.end_date).getTime() * 0.001],
            chartHeight: 400
          };
          copilotAddWidget('time-series', metaData);
          return;
        case 'CommodityWidget':
          addWidget('commodity');
          // copilotAddWidget('commodity', 'selectedList', data?.tags);
          return;
        case 'WeatherWidget':
          addWidget('weather');
          return;
        case 'YoutubeWidget':
          copilotAddWidget('youtube', {url: data?.address});
          return;
        case 'WebWidget':
          copilotAddWidget('web', {url: data?.address});
          return;
        case 'StickyNoteWidget':
          // draft.js에 필요한 데이터 형태로 변환하는 과정
          const contentState = ContentState.createFromText(data?.memo || '');
          const rawDraftContentState = convertToRaw(contentState);
          copilotAddWidget('sticky', {rawDraftContentState});
          return;
        case 'SpreadsheetWidget':
          return;
        case 'ThreeLandscapeWidget':
          addWidget('3d-landscape');
          return;
        case 'HmbWidget':
          addWidget('hmb');
          return;
      }
    } else if (command === 'close') {
      switch (type) {
        case 'TimeSeriesWidget':
          copilotRemoveWidget('TimeSeriesWidget');
          return;
        case 'CommodityWidget':
          copilotRemoveWidget('CommodityWidget');
          return;
        case 'WeatherWidget':
          copilotRemoveWidget('WeatherWidget');
          return;
        case 'YoutubeWidget':
          copilotRemoveWidget('YoutubeWidget');
          return;
        case 'WebWidget':
          copilotRemoveWidget('WebWidget');
          return;
        case 'StickyNoteWidget':
          copilotRemoveWidget('StickyNoteWidget');
          return;
        case 'SpreadsheetWidget':
          copilotRemoveWidget('SpreadsheetWidget');
          return;
        case 'ThreeLandscapeWidget':
          copilotRemoveWidget('ThreeLandscapeWidget');
          return;
        case 'HmbWidget':
          copilotRemoveWidget('HmbWidget');
          return;
      }
    }
  };

  const changeCurrentThread = (thread_id: string) => {
    const found = threadList.find((thread) => thread.id === thread_id);
    if (found) {
      setCurrentThread(found);
      // setDateFrom(found.data_range_from);
    }
  };

  const getThreadList = async () => {
    return sendRequest(user, 'thread_list', {}, '').then((res: ICopilotThreadReturn) => {
      setThreadList(res?.content);
      return res.content;
    });
  };

  const rename = (thread_id: string, newTitle: string): void => {
    sendRequest(user, 'title_change', {title: newTitle}, thread_id).then((res: ICopilotThreadReturn) => {
      setThreadList(res?.content);
      return res.content;
    });
  };
  const remove = (thread_id: string): void => {
    sendRequest(user, 'delete_thread', {}, thread_id).then((res: ICopilotThreadReturn) => {
      setCurrentThread(null);
      setThreadList(res?.content);
      return res.content;
    });
  };

  const userTypeChange = (userIsOn: boolean): void => {
    const userType = userIsOn ? 'admin' : 'general';
    const msg = {user_type: userType} as ICopilotMessageParams;
    sendRequest(user, 'user_type', msg, '').then((res: ICopilotResponse) => {
      setThreadList([...threadList]);
    });
  };

  /*
  const checkAvailableDateRange = () => {
    const msg = {upload_data: isOn} as ICopilotMessageParams;
    sendRequest(user, 'available_data_range', msg, '').then((res: IDataRangeReturn) => {
      setDataRange(res.content);
    });
  };
*/

  const getRawData = async (thread_id: string) => {
    const msg = {upload_data: isOn} as ICopilotMessageParams;
    const response = (await sendRequest(user, 'raw_data', msg, thread_id)) as ICopilotDataReturn;
    const responseData = response.content;
    return response ? JSON.parse(responseData.replace(/\bNaN\b/g, 'null')) : [];
  };

  const collection = {
    threadList,
    currentThread,
    isWaiting,
    isNewChat,
    isOn,
    voiceIsOn,
    userIsOn,
    fileId,
    isPlaying,
    dataList,
    dataRange,
    sendRequest,
    changeCurrentThread,
    getThreadList,
    setIsOn,
    setVoiceIsOn,
    setUserIsOn,
    setFileId,
    userTypeChange,
    setIsPlaying,
    sendQuestion,
    rename,
    remove,
    getRawData,
    changeDataRange,
    setDataList
  };

  return <CopilotContext.Provider value={collection}>{children}</CopilotContext.Provider>;
}

export default CopilotProvider;
