import {useContext, useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Edge, Node, useReactFlow} from 'reactflow';
import {IApiReturnBasic, IFile, IReactFlowLayout} from 'api/data-types';
import {NEW_FILE_ROUTE} from 'components/menu/constants';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {CommonContext} from 'components/common/CommonProvider';
import useApi from 'api/useApi';
import {AuthContext} from 'components/auth/AuthProvider';

export function useCanvasEffect(nodes: Node[], edges: Edge[]) {
  const api = useApi();
  const {file, fileList, create, load, localSave} = useContext(ProcessCanvasContext);
  const {userProfile} = useContext(AuthContext);
  const {showModal, showSpinner, hideSpinner} = useContext(CommonContext);
  const {id} = useParams();
  const reactFlow = useReactFlow();
  const navigate = useNavigate();
  const nodeRef = useRef(null);
  const edgeRef = useRef(null);
  const changeFlagRef = useRef(null);
  nodeRef.current = nodes;
  edgeRef.current = edges;
  changeFlagRef.current = file?.localInfo?.isChanged;

  useEffect(() => {
    if (!userProfile || !id) return;
    if (id === NEW_FILE_ROUTE) {
      if (!file?._id) {
        create();
      }
    } else if (id !== file?._id) {
      // showSpinner({});
      api
        .post<IApiReturnBasic>('/file_manage/get_file_data', {
          fileType: 'process_canvas',
          _id: id
        })
        .then((res) => {
          if (res?.success) {
            load(res?.data as IFile);
            hideSpinner();
          } else {
            const found = fileList.find((file) => file._id === id);
            // 다른 사용자가 delete한 파일 or private로 변경한 파일을 save as 했을 경우 Invalid File modal이 뜨지 않도록 조건 추가
            if (!found) {
              return;
            }

            showModal({
              title: 'Invalid File',
              content: 'There is no Process Canvas file',
              onConfirm: () => navigate(`/pc`),
              onClose: () => navigate(`/pc`)
            });
          }
        });
    }
  }, [id, file?._id, userProfile]);

  // useEffect(() => {
  //   if (!file) {
  //     return;
  //   }
  //   const stateData = file?.stateData as IReactFlowLayout;
  //   if (stateData?.nodes) {
  //     /**
  //      *  [MIGRATION-2]
  //      *  PMM-452/change-name branch 'ExtendedSpreadsheetWidget' => 'DataSheetWidget' 로 변경함에 이어서 호환성 해결
  //      */
  //     const nodes = stateData?.nodes?.map((item) =>
  //       item?.type === 'ExtendedSpreadsheetWidget' ? {...item, type: 'DataSheetWidget'} : item
  //     ) as Node[];
  //
  //     const edges = stateData?.edges as Edge[];
  //     reactFlow.setEdges(edges);
  //     reactFlow.setNodes(nodes);
  //   } else {
  //     const nodes = stateData as unknown;
  //     reactFlow.setNodes(nodes as Node[]);
  //   }
  // }, [file?.stateData]);

  useEffect(() => {
    return () => {
      if (nodeRef?.current) {
        localSave(nodeRef.current, edgeRef.current);
        reactFlow.setNodes((nodes) => nodes.map((item) => ({...item, selected: false})));
      }
    };
  }, []);

  useEffect(() => {
    const beforeUnloadAlterUser = (e: BeforeUnloadEvent) => {
      if (changeFlagRef.current) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', beforeUnloadAlterUser);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadAlterUser);
    };
  }, []);
}
