import React, {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import actionReducer from 'components/mpfd/hooks/reducer/action';
import {AnnotationTypes, IAnnotation, IDetectedLine, IImageData, IPfdAction, IPfdState} from 'components/mpfd/type';
import {CommonContext} from 'components/common/CommonProvider';
import {ISaveFile} from 'components/menu/pulldown/ProcessCanvasSaveAsModal';
import {annotation, CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';
import {metaPfdMenu, NEW_FILE_ROUTE} from 'components/menu/constants';
import {defaultMat, removeFileExtension} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {
  IApiReturnBasic,
  IFile,
  IGetFileListReturn,
  IImportFileReturn,
  IImportFileReturnData,
  IMPfdData
} from 'api/data-types';
import {ICloseWarning} from 'components/menu/pulldown/MetaPfdCloseWarningModal';
import useLatestTagHandler, {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import {AuthContext} from 'components/auth/AuthProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {useRafState} from 'react-use';
import {Matrix} from 'transformation-matrix';
import {ISaveStatusModalState} from 'components/menu/pulldown/SaveLoadingModal';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {getUniqueKey} from 'utils/commons';
import useApi from 'api/useApi';
import {ISocketFileManage} from 'socket/types';
import useLatestNodeHandler, {ILatestNodeHandlerReturn} from 'hooks/useLatestNodeHandler';

type AnnotationLayerAction = (
  region: IAnnotation,
  targetPointsIdx?: number,
  point?: {x: number; y: number},
  clientXY?: {x: number; y: number}
) => void;

export type IMetaPfdContext = {
  fileList: IFile[];
  getFileById(id: string): IFile;
  getFileList(): Promise<IFile[]>;
  file: IFile | null;
  isFileOwner: boolean;
  isFilePrivate: boolean;
  create(imageInfo: Partial<IImageData>): void;
  change(imageInfo: Partial<IImageData>): void;
  load(file: IFile | null): void;
  close(): void;
  save(fileName: string, isSaveAs: boolean, state?: IPfdState): Promise<IFile>;
  localSave(): void;
  remove(id: string): Promise<IApiReturnBasic>;
  exportFile(fileName: string): Promise<Blob | null>;
  importFile(targetFile: File): Promise<IImportFileReturnData | null>;
  hasZoomScaleState: [boolean, Dispatch<SetStateAction<boolean>>];
  changeMatrix: Dispatch<SetStateAction<Matrix>>;
  matrix: Matrix;
  isShowPanelLayout: boolean;
  state: IPfdState;
  saveLoadingModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  saveState: [ISaveStatusModalState, Dispatch<SetStateAction<ISaveStatusModalState>>];
  saveAsModalState: [ISaveFile, Dispatch<SetStateAction<ISaveFile>>];
  closeModalState: [ICloseWarning, Dispatch<SetStateAction<ICloseWarning>>];
  allowCopyModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  ownershipModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  accessibilityModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  exportModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  importModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  stageEnteredState: [boolean, Dispatch<SetStateAction<boolean>>];
  setIsShowPanelLayout(isShowPanelLayout: boolean): void;
  dispatchToReducer(action: IPfdAction): void;
  annotationAction: {
    selectAnnotation(region: IAnnotation): void;
    deleteAnnotations(regions: IAnnotation[]): void;
    mergeAnnotations(regions: IAnnotation[]): void;
    modifyAnnotation(region: IAnnotation): void;
    unSelectAnnotation(): void;
    beginMovePoint(region: IAnnotation): void;
    beginBoxTransform(region: IAnnotation, directions: number[]): void;
    removeLine: AnnotationLayerAction;
    splitAnnotationViaLine: AnnotationLayerAction;
    createBreakPointOnLine: AnnotationLayerAction;
    splitAnnotationViaPoint: AnnotationLayerAction;
    beginMovePointOfLine: AnnotationLayerAction;
    beginSelectPointOfLine: AnnotationLayerAction;
    removeBreakPointOnLine: AnnotationLayerAction;
  };
  utilAction: {
    cancel(): void;
  };
  uploadDetectionFile(): Promise<boolean>;
  detectLine(x: number, y: number): Promise<boolean>;
  isDetectProgress: boolean;
  onChangeDetectProgress(b: boolean): void;
  mPfdModal: {type: null | string};
  showMPfdModal(type: string): void;
  hideMPfdModal(): void;
  pfdList: string[];
  latestTagHandlerForTable: ILatestTagHandlerReturn;
  latestTagHandlerForChart: ILatestTagHandlerReturn;
  latestNodeHandlerForTable: ILatestNodeHandlerReturn;
  latestNodeHandlerForChart: ILatestNodeHandlerReturn;
  prevOwner: string;
  isPublic: boolean;
  isRename: boolean;
  isAllowCopy: boolean;
  isShowPublicState: [boolean, Dispatch<SetStateAction<boolean>>];
  isShowOwnerState: [boolean, Dispatch<SetStateAction<boolean>>];
  isTransferOwnerState: [boolean, Dispatch<SetStateAction<boolean>>];
  isNewNameState: [boolean, Dispatch<SetStateAction<boolean>>];
  socketMessageState: [ISocketFileManage, React.Dispatch<React.SetStateAction<ISocketFileManage>>];
  setPrevOwner(prevOwner: string): void;
  setIsPublic(isPublic: boolean): void;
  setIsRename(isRename: boolean): void;
  setIsAllowCopy(isAllowCopy: boolean): void;
  allowCopy(): Promise<boolean>;
  transferOwnership(): Promise<boolean>;
  changeAccessibility(): Promise<boolean>;
  rename(newFileName: string): Promise<void>;
  checkIsChanged(confirmAction: () => void, actionType: string, afterActionAddress?: string): boolean;
  isMetaPfdModalOpened: boolean;
};

export const MetaPfdContext: Context<IMetaPfdContext> = createContext(null);

function MetaPfdProvider({children}: PropsWithChildren) {
  const api = useApi();
  const {addNotice, version, showContextMenu} = useContext(CommonContext);
  const {userProfile, ownerName} = useContext(AuthContext);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
  const [file, setFile] = useState<IFile>(null);
  const isFileOwner = Boolean(file?.owner && file?.owner === userProfile?.username);
  const isFilePrivate = Boolean(file?.owner && file?.owner === userProfile?.username && file.public === true);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const viewPullDownMenu = metaPfdMenu.find((menu) => menu.id === 'view').children;
  const hasZoomScaleState = useState(viewPullDownMenu.find((menu) => menu.id === 'zoom-panel').checked);
  const saveLoadingModalState = useState<boolean>(false);
  const saveState = useState<ISaveStatusModalState>();
  const [, setSaveLoadingState] = saveState;
  const saveAsModalState = useState<ISaveFile>();
  const closeModalState = useState<ICloseWarning>();
  const allowCopyModalState = useState<boolean>(false);
  const ownershipModalState = useState<boolean>(false);
  const accessibilityModalState = useState<boolean>(false);
  const exportModalState = useState<boolean>(false);
  const importModalState = useState<boolean>(false);
  const [, setCloseModal] = closeModalState;
  const [pfdList, setPfdList] = useState([]);
  const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  const latestTagHandlerForChart = useLatestTagHandler({type: 'latest_count', latest_count: 10});
  const latestNodeHandlerForTable = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  const latestNodeHandlerForChart = useLatestNodeHandler({type: 'latest_count', latest_count: 10});
  const [prevOwner, setPrevOwner] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [isAllowCopy, setIsAllowCopy] = useState(true);
  const isShowPublicState = useState<boolean>(true);
  const isShowOwnerState = useState<boolean>(true);
  const isTransferOwnerState = useState<boolean>(false);
  const isNewNameState = useState<boolean>(false);
  const socketMessageState = useState<ISocketFileManage>(null);
  const [matrix, changeMatrix] = useRafState(defaultMat);
  const [isShowPanelLayout, setIsShowPanelLayout] = useState(true);
  const stageEnteredState = useState(true);
  // :todo Common Provider modal 대체
  const [mPfdModal, setMPfdModal] = useState({type: null});
  const isMetaPfdModalOpened =
    allowCopyModalState[0] ||
    ownershipModalState[0] ||
    exportModalState[0] ||
    Boolean(closeModalState[0]) ||
    Boolean(saveAsModalState[0]) ||
    importModalState[0] ||
    Boolean(mPfdModal.type);

  const getFileList = async (): Promise<IFile[]> => {
    const response = await api.post<IGetFileListReturn>('/file_manage/get_file_list', {
      fileType: 'meta_pfd',
      exceptState: true
    });
    if (response) {
      const list = response?.data as IFile[];
      setFileList(list);
      return list;
    }
  };

  const getFileById = (id: string): IFile => {
    return fileList.find((file) => file._id === id);
  };

  const remove = async (_id: string) => {
    return api.post<IApiReturnBasic>('/file_manage/delete_file', {_id, fileType: 'meta_pfd'});
  };

  const create = (imageInfo: Partial<IImageData>): void => {
    dispatchToReducer({type: 'Initialize'});
    const imgFile = imageInfo?.imgFile;
    let newFile = {
      fileName: removeFileExtension(imageInfo?.fileName),
      fileType: 'meta_pfd',
      owner: userProfile?.username,
      pidName: imageInfo?.fileName,
      createdUser: 'Simacro',
      stateData: {},
      _id: 'new-file',
      localInfo: imgFile && {
        uploadImageDataUrl: imageInfo.imageSrc,
        uploadImageFile: imgFile
      }
    } as IFile;
    setFile(newFile);
    addNotice({text: 'Created Meta Pfd file.'});
  };

  const load = (file: IFile): void => {
    dispatchToReducer({type: 'Initialize'});
    const stateData = file.stateData as IMPfdData;
    // MIGRATION-5 : allow_copy 값이 없는 예전 저장 파일은 기본적으로 true 로 설정
    file.allow_copy = file?.allow_copy === undefined ? true : Boolean(file?.allow_copy);
    dispatchToReducer({type: 'onLoad', fileData: {...stateData, detectionInfo: {}}});
    setFile({...file, localInfo: {...file?.localInfo, loadComplete: true}});
    setIsPublic(Boolean(file?.public));
    setIsAllowCopy(Boolean(file?.allow_copy));
    if (window.location.pathname !== `/mpfd/${NEW_FILE_ROUTE}`) {
      addNotice({text: 'Load complete Meta Pfd file.'});
    }

    if (stateData?.detectionInfo?.detectedFileName) {
      api
        .post<IApiReturnBasic>('/line_detection/check_detection_file', {
          detection_id: stateData?.detectionInfo?.detectedFileName
        })
        .then((res) => {
          if (!res?.success) {
            dispatchToReducer({
              type: 'INITIALIZE_DETECTION_INFO',
              detectionInfo: {
                viewport: {width: 0, height: 0},
                detectedFileName: '',
                detectedLines: []
              }
            });
          } else {
            dispatchToReducer({
              type: 'INITIALIZE_DETECTION_INFO',
              detectionInfo: stateData.detectionInfo
            });
          }
        });
    } else {
      dispatchToReducer({
        type: 'INITIALIZE_DETECTION_INFO',
        detectionInfo: stateData.detectionInfo
      });
    }
  };
  const close = (): void => {
    setFile(null);
    dispatchToReducer({type: 'Initialize'});
  };

  const change = (imageInfo: Partial<IImageData>) => {
    setFile((prev) => ({...prev, pidName: imageInfo.pidName}));
  };

  const [state, dispatchToReducer] = useReducer(actionReducer, CONST.state);
  const pfdStateRef = useRef(null);
  pfdStateRef.current = state;
  const pfdFileRef = useRef(null);
  pfdFileRef.current = file;
  const [, setDetectionApiLoading] = useState(false);
  const [isDetectProgress, setIsDetectProgress] = useState(false);

  const onChangeDetectProgress = (bool: boolean) => {
    setIsDetectProgress(bool);
  };

  const rename = async (newFileName: string): Promise<void> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'meta_pfd',
      update: {
        fileName: newFileName
      }
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((res) => {
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);
      if (res.success) {
        setFile((prev) => ({...prev, fileName: newFileName}));
      }
    });
  };

  const save = async (fileName: string, isSaveAs: boolean, pfdState: IPfdState): Promise<IFile> => {
    setSaveLoadingState(
      !isSaveAs
        ? {title: 'Save', stepMessage1: 'Saving current file'}
        : {title: 'Save As', stepMessage1: 'Saving current file'}
    );
    let tempState = pfdState || state;

    const fileData = {
      ...file,
      fileName: fileName || tempState?.saveName,
      // pidName: '',
      // user: 'Simacro',
      owner: userProfile.username,
      public: isPublic,
      allow_copy: isAllowCopy,
      stateData: {
        regions: tempState.regions,
        detectionInfo: tempState?.detectionInfo,
        imageInfo: {
          imageSourceType: tempState?.images?.imgSourceType,
          naturalWidth: tempState?.images?.naturalWidth,
          naturalHeight: tempState?.images?.naturalHeight
        },
        cfg: tempState?.cfg
      }
    } as IFile;

    if (fileData.localInfo) {
      delete fileData.localInfo;
    }
    if (isSaveAs || fileData._id === 'new-file') {
      delete fileData._id;
    }
    if (!fileName) return;
    const formData = new FormData();
    formData.append('data', JSON.stringify(fileData));

    if (tempState.images.imgSourceType === 'upload') {
      let myFile: File;
      if (tempState.images?.imgFile) {
        myFile = tempState?.images?.imgFile;
      } else if (tempState.images?.imgBlob) {
        myFile = new File([tempState.images.imgBlob], tempState.images.fileName, {
          type: tempState.images.imgBlob.type
        });
      }
      if (!myFile) return;
      formData.append('file', myFile);
    }
    return api.post<IApiReturnBasic>('/file_manage/save_file', formData, {type: 'formData'}).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState(
            !isSaveAs
              ? {title: 'Save', stepMessage2: 'Save Completed'}
              : {title: 'Save As', stepMessage2: 'Save Completed'}
          ),
        1000
      ); // modal이 1초 이상 보이도록 하기 위함

      addNotice({text: 'Save complete Meta Pfd file.'});
      getFileList().then();
      dispatchToReducer({type: 'ON_SAVE'});
      return response.data as IFile;
    });
  };

  const localSave = (): void => {
    if (pfdFileRef.current) {
      setFile((prev) => ({
        ...pfdFileRef.current,
        stateData: {
          regions: pfdStateRef.current?.regions,
          detectionInfo: pfdStateRef.current?.detectionInfo,
          cfg: {
            isDisplayLabels: Boolean(pfdStateRef.current?.cfg?.isDisplayDataValues),
            isDisplayDataValues: Boolean(pfdStateRef.current?.cfg?.isDisplayDataValues),
            isLiveUpdateDataValues: Boolean(pfdStateRef.current?.cfg?.isLiveUpdateDataValues)
          }
        },
        localInfo: {
          ...pfdFileRef?.current?.localInfo,
          loadComplete: false
        }
      }));
    }
  };

  const checkIsChanged = (confirmAction: () => void, actionType: string, afterActionAddress?: string) => {
    const isChangedFlag = pfdStateRef?.current?.isChanged;
    if (isChangedFlag) {
      setCloseModal({
        title: 'File Changed',
        content: !isFileOwner
          ? `This layout file has unsaved changes!
            However, you cannot save to this specific layout file 
            because your user ID does not match the file owner.
            Please save your changes as a new and distinct layout file.`
          : `There are unsaved changes! 
            Do you want to save this file?`,
        fileName: file?.fileName,
        isSaveAs: false,
        actionType,
        confirmAction,
        afterActionAddress,
        pfdState: state,
        confirmLabel: 'Save As New File'
      });
      return false;
    } else {
      confirmAction();
      return true;
    }
  };
  const allowCopy = async (): Promise<boolean> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'meta_pfd',
      update: {
        allow_copy: !isAllowCopy
      }
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);
      if (response.success) {
        setFile((prevState) => ({...prevState, allow_copy: !prevState.allow_copy}));
        return response.success;
      }
    });
  };

  const transferOwnership = async (): Promise<boolean> => {
    const updateData = {
      file_id: file._id,
      file_type: 'meta_pfd',
      update: {
        owner: ownerName
      }
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      if (response.success) {
        setFile((prevState) => ({...prevState, owner: updateData.update.owner}));
        return response.success;
      }
    });
  };

  const changeAccessibility = async (): Promise<boolean> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'meta_pfd',
      update: {public: !isPublic}
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState({
            title: 'Save Successful',
            stepMessage2: `This file has successfully been saved and set as ${!isPublic ? 'public' : 'private'}`
          }),
        1000
      );
      // addNotice({text: `Change complete to ${isPublic ? 'private' : 'public'}`});
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);

      if (response.success) {
        setFile((prevState) => ({...prevState, public: !prevState.public}));
        return response.success;
      }
    });
  };

  const uploadDetectionFile = async () => {
    const detectTargetFileUpload = async (state: IPfdState) => {
      if (!!state?.detectionInfo?.detectedFileName) {
        return;
      }
      const formData = new FormData();
      let myFile: File;
      if (state.images?.imgFile) {
        myFile = state?.images?.imgFile;
      } else if (state.images?.imgBlob) {
        myFile = new File([state.images.imgBlob], state.images.fileName, {
          type: state.images.imgBlob.type
        });
      }
      formData.append('file', myFile);
      formData.append('data', JSON.stringify({detection_id: state?.detectionInfo?.detectedFileName}));

      return await api
        .post<IApiReturnBasic>('/line_detection/detect_file', formData, {type: 'formData'})
        .then(function (res) {
          if (res?.success) {
            const data = res?.data as {view_port: any; detection_id: string};
            const [h, w] = data?.view_port;
            return {
              type: 'INITIALIZE_DETECTION_INFO',
              detectionInfo: {
                viewport: {width: w, height: h},
                detectedFileName: data.detection_id,
                detectedLines: []
              },
              selectedTool: 'line-detection' as ToolIds
            };
          }
        });
    };

    try {
      const dispatchObj = await detectTargetFileUpload(state);

      if (dispatchObj) {
        dispatchToReducer(dispatchObj);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    } finally {
    }
  };

  const detectLine = async (xP: number, yP: number) => {
    const getDetectLine = async (state: IPfdState, xP: number, yP: number) => {
      const {detectedFileName, viewport} = state.detectionInfo;
      const {width, height} = viewport;
      const x = xP * width;
      const y = yP * height;
      return api.post<IDetectedLine>(`/line_detection/click/${detectedFileName}/${x}/${y}`, {}).then((res) => {
        if (res.success) {
          const {line_data: data, line_number: number} = res.data;
          const filteredData = [];
          for (let i = 0; i < data.length; i++) {
            filteredData.push(data[i][0]);
            filteredData.push(data[i][1]);
          }
          const p = filteredData.map((item) => ({x: item[0] / width, y: item[1] / height}));

          let newLine;
          if (number) {
            newLine = {
              type: 'expanding-line' as AnnotationTypes,
              unfinished: false,
              points: p,
              highlighted: true,
              color: '#EE5C84',
              cls: 'Soft Sensor',
              id: getUniqueKey() + '-' + number,
              name: number,
              editingLabels: false,
              labelVisible: true,
              isShowTableHeader: true,
              chartPosition: {
                w: 0.1,
                h: 0.1
              },
              tablePosition: {
                w: 0.1,
                h: 0.1
              },
              metaPfdTagInfo: []
            };
          } else {
            const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'line');
            newLine = {
              type: 'expanding-line' as AnnotationTypes,
              unfinished: false,
              points: p,
              highlighted: true,
              color: '#EE5C84',
              cls: 'Soft Sensor',
              id: getUniqueKey() + '-' + number,
              name: newAnnotName + '-' + newAnnotNum,
              editingLabels: false,
              labelVisible: true,
              isShowTableHeader: true,
              chartPosition: {
                w: 0.1,
                h: 0.1
              },
              tablePosition: {
                w: 0.1,
                h: 0.1
              },
              metaPfdTagInfo: []
            };
          }

          let isExistSameLine = false;
          const lines = state.regions.filter((item) => item.type === 'expanding-line');
          for (let i = 0; i < lines?.length; i++) {
            let renderedLine = lines[i];
            if (p?.length !== renderedLine?.points?.length) {
              continue;
            }
            for (let i = 0; i < renderedLine?.points?.length; i++) {
              if (renderedLine?.points?.[i]?.x !== p?.[i]?.x || renderedLine?.points?.[i]?.y !== p?.[i]?.y) {
                break;
              }
              if (i === renderedLine?.points?.length - 1) {
                isExistSameLine = true;
                break;
              }
            }
            if (isExistSameLine) {
              break;
            }
          }

          if (isExistSameLine) {
            return;
          }

          const newDetectedLines = [
            ...state.detectionInfo.detectedLines.map((item: IAnnotation) => ({...item, highlighted: false})),
            newLine
          ] as IAnnotation[];

          return {
            type: 'MODIFY_DETECTION_LINES',
            region: newLine,
            detectionInfo: {detectedLines: newDetectedLines}
          };
        }
      });
    };

    try {
      setDetectionApiLoading(true);
      const dispatchObj = await getDetectLine(state, xP, yP);
      if (dispatchObj) {
        dispatchToReducer(dispatchObj);
      }
      setDetectionApiLoading(false);
      return true;
    } catch (e) {
      return false;
    } finally {
      setDetectionApiLoading(false);
    }
  };

  const showMPfdModal = (type: string) => {
    setMPfdModal({type});
  };

  const hideMPfdModal = () => {
    setMPfdModal({type: null});
  };

  const annotationLayerAction =
    (type: string) =>
    (
      region: IAnnotation,
      targetPointsIdx?: number,
      point?: {x: number; y: number},
      clientXY?: {x: number; y: number}
    ) => {
      dispatchToReducer({type, region, targetPointsIdx, point, clientXY});
    };

  const annotationAction = {
    selectAnnotation: (region: IAnnotation) => {
      dispatchToReducer({type: 'SELECT_REGION', region});
    },
    deleteAnnotations: (regions: IAnnotation[]) => {
      dispatchToReducer({type: 'DELETE_REGIONS', regions});
    },
    modifyAnnotation: (region: IAnnotation) => {
      dispatchToReducer({type: 'CHANGE_REGION', region});
    },
    mergeAnnotations: (regions: IAnnotation[]) => {
      dispatchToReducer({type: 'MERGE_LINE', regions});
    },
    unSelectAnnotation: () => {
      dispatchToReducer({type: 'UNSELECT_REGION'});
    },
    beginMovePoint: (region: IAnnotation) => {
      dispatchToReducer({type: 'BEGIN_MOVE_POINT', point: region});
    },
    beginBoxTransform: (region: IAnnotation, directions: number[]) => {
      dispatchToReducer({type: 'BEGIN_BOX_TRANSFORM', box: region, directions});
    },
    removeLine: annotationLayerAction('REMOVE_PARTS_OF_REGION'),
    splitAnnotationViaLine: annotationLayerAction('SPLIT_LINE_VIA_LINE'),
    createBreakPointOnLine: annotationLayerAction('CREATE_BREAK_POINT_REGION'),
    splitAnnotationViaPoint: annotationLayerAction('SPLIT_LINE_VIA_POINT'),
    beginMovePointOfLine: annotationLayerAction('BEGIN_MOVE_POINT_OF_LINE'),
    beginSelectPointOfLine: annotationLayerAction('BEGIN_SELECT_POINT_OF_LINE'),
    removeBreakPointOnLine: annotationLayerAction('REMOVE_BREAK_POINT_ON_LINE')
  };

  const utilAction = {
    cancel: () => {
      dispatchToReducer({type: 'CANCEL'});
      showContextMenu(null);
    }
  };

  const importFile = async (targetFile: File): Promise<IImportFileReturnData | null> => {
    const file = targetFile;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('info', JSON.stringify({owner: userProfile?.username}));
    const response = await api.post<IImportFileReturn>('/file_manage/import_file', formData, {type: 'formData'});

    if (response) {
      return response.data;
    }
    return null;
  };

  const exportFile = async (fileName: string): Promise<Blob | null> => {
    const fileData = {
      fileName,
      fileType: file.fileType,
      _id: file._id,
      version
    };

    const response = await api.exportFile('/file_manage/export_file', fileData);
    if (response) {
      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      // 응답 blob 이 뭔가 시원찮으면 실패 리턴
      if (!contentDisposition) return null;

      let filename = '';
      const filenameRegex = /filename\*?=(?:UTF-8'')?([^;]*)/i;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = decodeURIComponent(matches[1]);
      }

      if (!filename) return null;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return blob;
    }

    return null;
  };

  useEffect(() => {
    if (userProfile && token) {
      api.get<IApiReturnBasic>('/file_manage/get_default_mpfd_img_list').then((res) => {
        const r = res.data as IFile[];
        setPfdList(r);
      });
      getFileList().then();
    }
  }, [token, userProfile]);

  const collection = {
    fileList,
    getFileById,
    getFileList,
    file,
    isFileOwner,
    isFilePrivate,
    allowCopy,
    transferOwnership,
    changeAccessibility,
    create,
    change,
    remove,
    close,
    load,
    save,
    localSave,
    changeMatrix,
    matrix,
    state,
    saveLoadingModalState,
    saveState,
    saveAsModalState,
    allowCopyModalState,
    ownershipModalState,
    accessibilityModalState,
    stageEnteredState,
    hasZoomScaleState,
    isShowPanelLayout,
    exportModalState,
    importModalState,
    setIsShowPanelLayout,
    dispatchToReducer,
    annotationAction,
    uploadDetectionFile,
    detectLine,
    isDetectProgress,
    onChangeDetectProgress,
    mPfdModal,
    showMPfdModal,
    hideMPfdModal,
    pfdList,
    closeModalState,
    latestTagHandlerForTable,
    latestTagHandlerForChart,
    latestNodeHandlerForTable,
    latestNodeHandlerForChart,
    exportFile,
    importFile,
    prevOwner,
    isPublic,
    isRename,
    isAllowCopy,
    isShowPublicState,
    isShowOwnerState,
    isTransferOwnerState,
    isNewNameState,
    socketMessageState,
    setPrevOwner,
    setIsPublic,
    setIsRename,
    setIsAllowCopy,
    rename,
    checkIsChanged,
    isMetaPfdModalOpened,
    utilAction
  };

  return <MetaPfdContext.Provider value={collection}>{children}</MetaPfdContext.Provider>;
}

export default MetaPfdProvider;
