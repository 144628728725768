import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {io} from 'socket.io-client';
import {getApiHost} from 'api/function';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {useParams} from 'react-router-dom';
import {ISocketFileManage} from 'socket/types';
import {CommonContext} from 'components/common/CommonProvider';
import {AuthContext} from 'components/auth/AuthProvider';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {toDateFormat} from 'utils/commons';
import styled from 'styled-components';
import {MetaPfdContext} from 'components/mpfd';
import useMetaPfdCommand from 'components/menu/pulldown/useMetaPfdCommand';

const ModalContent = styled.div`
  margin-bottom: -26px;
  .owner,
  .day,
  .time {
    font-weight: 700;
  }
`;

function useSocket(): void {
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;

  const {id} = useParams();
  const {userProfile} = useContext(AuthContext);
  const {showModal, hideModal} = useContext(CommonContext);

  const pc = useContext(ProcessCanvasContext);
  const mpfd = useContext(MetaPfdContext);

  const pcCommand = useProcessCanvasCommand();
  const metaPfdCommand = useMetaPfdCommand();
  const [, setSavePcFileModalData] = pc.saveAsModalState;
  const [, setSaveMPfdFileModalData] = mpfd.saveAsModalState;
  const [, setIsPcTransferOwner] = pc.isTransferOwnerState;
  const [, setIsMPfdTransferOwner] = mpfd.isTransferOwnerState;
  const [, setIsPcNewName] = pc.isNewNameState;
  const [, setIsMPfdNewName] = mpfd.isNewNameState;
  const [, setSocketPcMessageState] = pc.socketMessageState;
  const [, setSocketMPfdMessageState] = mpfd.socketMessageState;

  /**
   * socket 연결
   */
  const onReceiveConnect = (): void => {
    console.log('Connected to server');
  };

  /**
   * 파일 변경 message 도착
   */
  const onReceiveFileManage = useCallback(
    async (message: ISocketFileManage) => {
      const {action, fileId, fileType, execution_time, update_data} = message;

      const file = fileType === 'process_canvas' ? pc.file : mpfd.file;
      const isFileOwner = fileType === 'process_canvas' ? pc.isFileOwner : mpfd.isFileOwner;

      console.log(message);

      fileType === 'process_canvas' ? setSocketPcMessageState(message) : setSocketMPfdMessageState(message);

      const day = toDateFormat(execution_time * 1000, 'YYYY-MM-DD');
      const time = toDateFormat(execution_time * 1000, 'HH:mm:ss');

      // 내가 다루고 있는 파일일 경우 아무 동작하지 않게
      if (isFileOwner && id === fileId) return;

      if (fileType === 'process_canvas') {
        await pc.getFileList();
      } else {
        await mpfd.getFileList();
      }

      // fileType === 'process_canvas' ? await pc.getFileList() : await mpfd.getFileList();

      switch (action) {
        case 'save_file':
          // 현재 열고있는 파일이 다른 user에 의해 변경되었을 경우 모달을 띄움
          if (file?.owner !== userProfile?.username && fileId === file?._id) {
            showModal({
              title: 'File Changed',
              content: (
                <ModalContent>
                  This File was changed by <span className="owner">{file?.owner}</span> <br /> on{' '}
                  <span className="day">{day}</span> at <span className="time">{time}</span>. <br />
                  Do you want to reload the updated version?
                </ModalContent>
              ),
              confirmLabel: 'Reload',
              cancelLabel: 'Cancel',
              isShowCancel: true,
              onConfirm() {
                window.location.reload();
              }
            });
          }
          return;
        case 'delete_file':
          // 현재 열고있는 파일이 다른 user에 의해 삭제되었을 경우 모달을 띄움
          if (file?.owner !== userProfile?.username && fileId === file?._id) {
            showModal({
              title: 'File Changed',
              content: (
                <ModalContent>
                  This File was deleted by <span className="owner">{file?.owner}</span> <br /> on{' '}
                  <span className="day">{day}</span> at <span className="time">{time}</span>. <br />
                </ModalContent>
              ),
              confirmLabel: 'Save as',
              cancelLabel: 'Cancel',
              closeLabel: 'Close File',
              isShowClose: true,
              isShowCancel: true,
              onConfirm() {
                fileType === 'process_canvas'
                  ? setSavePcFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true})
                  : setSaveMPfdFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
              },
              onCancel() {
                hideModal();
              },
              onClose() {
                fileType === 'process_canvas' ? pcCommand.close() : metaPfdCommand.close();
              }
            });
          }
          return;
        case 'update_file':
          if (file?.owner !== userProfile?.username && fileId === file?._id) {
            const list = fileType === 'process_canvas' ? await pc.getFileList() : await mpfd.getFileList();
            const found = list.find((file) => file?._id === fileId);
            // transfer_ownership 조건
            if (update_data.owner) {
              fileType === 'process_canvas' ? pc.setPrevOwner(file.owner) : mpfd.setPrevOwner(file.owner);
              fileType === 'process_canvas' ? setIsPcTransferOwner(true) : setIsMPfdTransferOwner(true);
              file.owner = found.owner;
              fileType === 'process_canvas'
                ? setTimeout(() => setIsPcTransferOwner(false), 10000)
                : setTimeout(() => setIsMPfdTransferOwner(false), 10000);
            }
            // rename_file 조건
            else if (update_data.fileName) {
              file.fileName = found.fileName;
              fileType === 'process_canvas' ? pc.setPrevOwner(file.owner) : mpfd.setPrevOwner(file.owner);
              fileType === 'process_canvas' ? setIsPcNewName(true) : setIsMPfdNewName(true);
              fileType === 'process_canvas'
                ? setTimeout(() => setIsPcNewName(false), 10000)
                : setTimeout(() => setIsMPfdNewName(false), 10000);
            }
            // switch_public 조건
            else if (update_data.public !== undefined) {
              if (update_data.public) return null;
              showModal({
                title: 'File Changed',
                content: (
                  <ModalContent>
                    This file's accessibility was modified by <span className="owner">{file.owner}</span>
                    <br /> on <span className="day">{day}</span> at <span className="time">{time}</span>. <br />
                  </ModalContent>
                ),
                confirmLabel: 'Save as',
                cancelLabel: 'Cancel',
                closeLabel: 'Close File',
                isShowClose: true,
                isShowCancel: true,
                onConfirm() {
                  fileType === 'process_canvas'
                    ? setSavePcFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true})
                    : setSaveMPfdFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
                },
                onCancel() {
                  hideModal();
                },
                onClose() {
                  fileType === 'process_canvas' ? pcCommand.close() : metaPfdCommand.close();
                }
              });
            }
            // allow copy 조건
            else if (update_data.allow_copy !== undefined) {
              showModal({
                title: 'File Changed',
                content: (
                  <ModalContent>
                    The option to save this file as a copy has been {update_data.allow_copy ? 'enabled ' : 'disabled '}
                    by <span className="owner">{file.owner}</span>
                    <br /> on <span className="day">{day}</span> at <span className="time">{time}</span>. <br />
                  </ModalContent>
                ),
                confirmLabel: 'Ok',
                onConfirm() {
                  update_data.allow_copy
                    ? fileType === 'process_canvas'
                      ? pc.setIsAllowCopy(true)
                      : mpfd.setIsAllowCopy(true)
                    : fileType === 'process_canvas'
                      ? pc.setIsAllowCopy(false)
                      : mpfd.setIsAllowCopy(false);
                  hideModal();
                },
                onCancel() {
                  update_data.allow_copy
                    ? fileType === 'process_canvas'
                      ? pc.setIsAllowCopy(true)
                      : mpfd.setIsAllowCopy(true)
                    : fileType === 'process_canvas'
                      ? pc.setIsAllowCopy(false)
                      : mpfd.setIsAllowCopy(false);
                  hideModal();
                }
              });
            }
          }

          return;
      }
    },
    [pc.isFileOwner, mpfd.isFileOwner, pc.file, mpfd.file, userProfile]
  );

  const getWebsocketHost = (url: string): string => {
    console.log(url);
    if (url.startsWith('https://')) {
      // For HTTPS URLs, use WSS (WebSocket Secure)
      return url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
      // For HTTP URLs, use WS (WebSocket)
      return url.replace('http://', 'ws://');
    }
  };

  const webSocket = useRef<WebSocket | null>(null);
  const reconnectDelay = useRef<number>(1000);

  const connectWebSocket = () => {
    const host = getApiHost();
    const websocketHost = getWebsocketHost(host);
    webSocket.current = new WebSocket(websocketHost + '/ws?token=' + token);

    webSocket.current.onopen = () => {
      console.log('Connection opened');
      reconnectDelay.current = 1000; // 연결되면 재연결 대기 시간을 초기화
    };

    webSocket.current.onclose = (event) => {
      if (event.code === 4001) {
        console.log('Connection closed due to a new session.');
        // 새 세션으로 인한 종료이므로 재연결을 시도하지 않음
      } else {
        console.log('Connection closed due to other reasons.');
        // 네트워크 불안정이나 기타 이유로 닫힘
        // 점진적인 재연결 시도 (최대 지연 시간 제한)
        setTimeout(() => {
          console.log('WebSocket 재연결 시도 중...');
          connectWebSocket();
        }, reconnectDelay.current);

        // 재연결 대기 시간을 점진적으로 증가시키기 (최대 10초)
        reconnectDelay.current = Math.min(reconnectDelay.current * 2, 10000);
      }
    };

    webSocket.current.onerror = (error) => {
      console.log('WebSocket 오류:', error);
    };

    webSocket.current.onmessage = (msg) => {
      try {
        const data = JSON.parse(msg.data);
        onReceiveFileManage(data);
      } catch (error) {
        console.error('수신한 메시지를 처리하는 중 오류 발생:', error);
      }
    };
  };

  useEffect(() => {
    if (!userProfile || !token) return;

    connectWebSocket();
    return () => {
      webSocket.current?.close();
    };
  }, [userProfile, token]);
}

export default useSocket;
