import {PropsWithChildren, ReactElement, useRef} from 'react';
import styled from 'styled-components';
import HighchartsDisplay from 'components/pc/widgets/timeseries/HighchartsDisplay';
import LightningChartDisplay from 'components/pc/widgets/timeseries/LightningChartDisplay';
import {LightningChartHost} from 'components/pc/widgets/timeseries/LightningChartHost';
import {ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';

const Container = styled.div`
  background-color: #e9eff3;
  min-height: 50px;
  overflow: hidden;
  flex-shrink: 0;
`;

type IProps = PropsWithChildren & {
  docked?: boolean;
  height: number;
  loader: ITimeSeriesLoader;
};

const genRandomData = () => {
  const data = [];
  let prev = 0;
  for (let i = 0; i < 100_000; i += 1) {
    const y = prev + (Math.random() * 2 - 1);
    data.push(y);
    prev = y;
  }
  return data;
};

function TimeSeriesChart({height, ...rest}: IProps): ReactElement {
  const ref = useRef(null);

  return (
    <Container style={{height}} ref={ref}>
      <LightningChartDisplay {...rest} />
    </Container>
  );
}

export default TimeSeriesChart;
