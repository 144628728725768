import React, {Dispatch, RefObject, useState} from 'react';
import {IPosition} from 'components/common/types';

const getValueByRange = (v: number, min: number, max: number) => {
  if (v < min) return min;
  if (v > max) return max;
  return v;
};

type IPadding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};
type IReturn = [IPosition, (e: React.MouseEvent) => void, Dispatch<React.SetStateAction<IPosition>>];

function useDraggable(
  boundaryRef: RefObject<HTMLElement>,
  contentRef: RefObject<HTMLElement>,
  defaultPosition?: IPosition,
  boundaryPadding: IPadding = {top: 10, right: 10, bottom: 10, left: 10}
): IReturn {
  const [position, setPosition] = useState<IPosition>(defaultPosition || {x: 0, y: 0});

  /**
   * defaultPosition 이후에 api 를 통해 받아온 위젯의 설정 값을 세팅할 경우 사용
   * @param initialPosition
   */
  const setInitialPosition = (initialPosition: IPosition) => {
    setPosition(initialPosition);
  };

  const onMouseDown = (e: React.MouseEvent): void => {
    const {pageX, pageY} = e;
    const startPosition = {x: pageX, y: pageY};
    const boundary = boundaryRef.current.getBoundingClientRect();
    const content = contentRef.current.getBoundingClientRect();

    const onMouseMove = (e: MouseEvent): void => {
      const {pageX, pageY} = e;
      // console.log(`mouse move x:${pageX} y:${pageY}`);
      const deltaX = pageX - startPosition.x;
      const deltaY = pageY - startPosition.y;
      const x = getValueByRange(
        position.x + deltaX,
        Math.floor(boundaryPadding.left),
        Math.floor(boundary.width - content.width - boundaryPadding.right)
      );
      const y = getValueByRange(
        position.y + deltaY,
        Math.floor(boundaryPadding.top),
        Math.floor(boundary.height - content.height - boundaryPadding.bottom)
      );
      setPosition({x, y});
    };

    const onMouseUp = (): void => {
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, {once: true});
  };

  return [position, onMouseDown, setPosition];
}

export default useDraggable;
