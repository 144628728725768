import React, {useState} from 'react';
import {useGLTF} from '@react-three/drei';
import * as THREE from 'three';
import {useThree} from '@react-three/fiber';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';
import {Vector3Like} from 'three';
import {Vector3} from '@react-three/fiber/dist/declarations/src/three-types';
import {ISelectedLabelInfo, IManualCamera} from 'components/pc/widgets/ThreeLandscapeWidget';

type IProps = {
  modelScale: number;
  transparent: boolean;
  wireframes: boolean;
  blockVisibility: any;
  moveCamera(position: Vector3Like, camera: IManualCamera, scene: THREE.Scene, targetSize?: 'big' | 'small'): void;
  setSelectedLabel(label: ISelectedLabelInfo): void;
};

function AgcColumn({modelScale, transparent, wireframes, blockVisibility, moveCamera, setSelectedLabel}: IProps) {
  const {nodes, materials} = useGltfCustom('/gltf/Shells/Shells.gltf');
  const shellOpacity = transparent ? 0.5 : 1;
  const annotations = [];
  const wireframesMaterial = new THREE.MeshBasicMaterial({color: '#0f0f0f', wireframe: true});
  const materialShell = wireframes ? wireframesMaterial : materials['Simacro_Shells'];

  const {camera, scene} = useThree();

  const [hoveredMesh, setHoveredMesh] = useState(null);

  const meshClick = (focusedMesh) => {
    console.log('>>>>focusedMesh', focusedMesh);

    setSelectedLabel(null);

    const mesh = nodes[`${focusedMesh}_Shell`];
    moveCamera(mesh.position as Vector3Like, camera, scene, 'big');
  };

  return (
    <group dispose={null} scale={modelScale}>
      <mesh
        key="02"
        name={`Shell_02`}
        visible={blockVisibility['02']}
        geometry={nodes[`02_Shell_1`].geometry}
        // material-transparent
        material-opacity={shellOpacity}
        material={materialShell}
        position={nodes[`02_Shell`].position as Vector3}
        rotation={nodes[`02_Shell`].rotation}
        scale={nodes[`02_Shell`].scale}
        onClick={() => {
          meshClick('02');
        }}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/Shells/Shells.gltf');

export default AgcColumn;
