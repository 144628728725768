import {useContext, useRef, useState} from 'react';
import {DataContext} from 'api/DataProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {useNavigate, useParams} from 'react-router-dom';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {
  alreadyDetectedModalOptions,
  NEW_FILE_ROUTE,
  onlySvgFileDetectableModalOptions
} from 'components/menu/constants';
import {AuthContext} from 'components/auth/AuthProvider';
import {IFile} from 'api/data-types';
import {compose, rotate, scale, translate} from 'transformation-matrix';

type IReturn = {
  create(): void;
  change(): void;
  close(): void;
  save(): void;
  saveAs(fileName?: string): void;
  rename(): void;
  remove(item: IFile, id: string): void;
  openAllowCopyModal(): void;
  openOwnershipModal(): void;
  openChangeAccessibilityModal(): void;
  showGlobalSettings(): void;
  zoomIn(): void;
  zoomOut(): void;
  zoomTo(zoomLevel: number): void;
  fullScreen(): void;
  toggleLabel(): void;
  toggleData(): void;
  lineDetect(): void;
  importFile(): void;
  exportFile(): void;
  toggleZoomScale(): void;
};

function useMetaPfdCommand(): IReturn {
  const {userProfile} = useContext(AuthContext);

  const {
    file,
    fileList,
    saveLoadingModalState,
    saveAsModalState,
    allowCopyModalState,
    ownershipModalState,
    accessibilityModalState,
    hasZoomScaleState,
    getFileList,
    showMPfdModal,
    close,
    save,
    remove,
    setIsRename,
    isFileOwner,
    checkIsChanged,
    state,
    matrix,
    changeMatrix,
    exportModalState,
    importModalState,
    dispatchToReducer
  } = useContext(MetaPfdContext); //saveAsModalState,
  const {globalSettingsModalState} = useContext(DataContext);
  const [, setIsShowSetting] = globalSettingsModalState;
  const [, setHasZoomScale] = hasZoomScaleState;
  const {addToast, showModal, showSpinner, hideSpinner} = useContext(CommonContext);
  const navigate = useNavigate();
  const {id} = useParams();
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const [, setSaveFileModalData] = saveAsModalState;
  const [defaultMousePositon, setDefaultMousePositon] = useState({x: 0, y: 0});
  const [, setImportModalData] = importModalState;
  const [, setIsShowExportModal] = exportModalState;
  const [, setIsAllowCopyModal] = allowCopyModalState;
  const [, setIsShowOwnerModal] = ownershipModalState;
  const [, setIsShowAccessibilityModal] = accessibilityModalState;

  const createLayout = (): void => {
    // showMPfdModal('create');
    const fnc = () => {
      showMPfdModal('create');
    };
    if (checkIsChanged(fnc, 'create')) {
      fnc();
    }
  };

  const changeLayout = (): void => {
    showMPfdModal('change');
  };

  const closeLayout = (): void => {
    const fnc = () => {
      close();
      navigate('/mpfd');
    };
    if (checkIsChanged(fnc, 'close')) {
      fnc();
    }
  };

  const saveLayout = (): void => {
    if (id === NEW_FILE_ROUTE) {
      // 저장한 적이 없으면 모달을 띄움
      setIsRename(false);
      setSaveFileModalData({title: 'Save', fileName: file?.fileName, isSaveAs: false});
      console.log('no save');
    } else if (!isFileOwner) {
      // user name과 file owner가 일치하지 않을 때 상황을 알리는 모달을 띄움
      showModal({
        title: 'Warning',
        content: `Only the file owner can save to this file. \n Please save this file as a new instance to keep your changes.`,
        confirmLabel: 'Save As',
        cancelLabel: 'Cancel',
        isShowCancel: true,
        onConfirm() {
          setSaveFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
        }
      });
    } else {
      // 저장한 적이 있으면 그냥 저장 (Save loading modal 띄우기)
      setIsShowSaveLoadingModal(true);
      save(file.fileName, false).then(() => {
        setSaveFileModalData(undefined);
      });
    }
  };

  const removeLayout = (item: IFile, id: string): void => {
    if (id === file?._id) {
      // && file?.updatedTime === item?.updatedTime
      addToast({text: 'Can not delete opened file', delay: 5000});
    } else if (userProfile?.username === item.owner) {
      const found = fileList.find((file) => file._id === id);
      showModal({
        title: 'Meta PFD',
        content: (
          <>
            Are you sure you want to delete this file?
            <br />
            <strong>{found?.fileName}</strong>
            <br />
            This action cannot be undone. Please confirm to proceed with the action.
          </>
        ),
        onConfirm() {
          remove(id).then((response) => {
            if (response?.success) {
              getFileList().then();
            }
          });
        },
        isShowCancel: true,
        confirmLabel: 'Delete File',
        confirmVariant: 'danger'
      });
    } else {
      // user name과 file owner가 일치하지 않을 때 삭제 막기
      showModal({
        title: 'Action Denied',
        content: `Only the owner of this file may delete this file`,
        cancelLabel: 'Close',
        isShowCancel: true,
        isShowConfirm: false
      });
    }
  };

  const openSaveAsModal = (fileName?: string): void => {
    setIsRename(false);
    setSaveFileModalData({title: 'Save as', fileName: fileName || file.fileName, isSaveAs: true});
  };

  const openRenameModal = (): void => {
    setIsRename(true);

    if (!isFileOwner) {
      setIsRename(false);

      showModal({
        title: 'Rename',
        content: `You can't rename to the current file if the user ID does not match the file owner.\n Use 'Save As' to rename this file.`,
        confirmLabel: 'Save as',
        confirmVariant: 'danger',
        cancelLabel: 'Cancel',
        isShowCancel: true,
        onConfirm() {
          setSaveFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
        }
      });
    } else {
      setSaveFileModalData({title: 'Rename', fileName: file.fileName, isSaveAs: false});
    }
  };

  const openAllowCopyModal = () => {
    setIsAllowCopyModal(true);
  };

  // ownership 이전할 때 변동사항이 있을 경우 저장여부를 물어본다
  const openOwnershipModal = () => {
    if (state.isChanged) {
      showModal({
        title: 'Transfer Ownership',
        content: 'The layout file has been changed.\nDo you want to save before transfer ownership?',
        isShowCancel: true,
        confirmLabel: 'Save',
        onConfirm() {
          // 저장하고 next();
          showSpinner({});
          const runSave = async () => {
            const savedFile = await save(file.fileName, false);
            if (savedFile) {
              setIsShowOwnerModal(true);
              hideSpinner();
            }
          };
          runSave();
        }
      });
    } else {
      setIsShowOwnerModal(true);
    }
  };

  const openChangeAccessibilityModal = (): void => {
    setIsShowAccessibilityModal(true);
  };

  const showGlobalSettings = (): void => {
    setIsShowSetting(true);
  };

  const zoomIn = (): void => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const centerX = windowWidth / 2;
    const centerY = windowHeight / 2;
    const canvasEl = document.getElementById('standard-canvas');

    const [mx, my] = [centerX - canvasEl.getBoundingClientRect().left, centerY - canvasEl.getBoundingClientRect().top];
    let scaleNum = 0.8;
    let newMat;

    newMat = compose(matrix, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum)));
    // if (newMat.a > 2) newMat = compose([newMat, scale(2 / newMat.a)]);
    // if (newMat.a < 0.1) newMat = compose([newMat, scale(0.1 / newMat.a)]);
    newMat = compose([newMat, translate(-mx, -my)]);
    if (newMat.a < 0.1) {
      return;
    }
    changeMatrix({...newMat});
  };

  const zoomOut = (): void => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const centerX = windowWidth / 2;
    const centerY = windowHeight / 2;
    const canvasEl = document.getElementById('standard-canvas');

    const [mx, my] = [centerX - canvasEl.getBoundingClientRect().left, centerY - canvasEl.getBoundingClientRect().top];
    let scaleNum = 1.2;
    let newMat;

    newMat = compose(matrix, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum)));
    // if (newMat.a > 2) newMat = compose([newMat, scale(2 / newMat.a)]);
    // if (newMat.a < 0.1) newMat = compose([newMat, scale(0.1 / newMat.a)]);
    newMat = compose([newMat, translate(-mx, -my)]);
    if (newMat.a > 5) {
      return;
    }
    changeMatrix({...newMat});
  };

  const zoomTo = (zoomLevel: number): void => {
    // todo zoom reset 기준 위치 변경 필요
    changeMatrix((prevMat) => {
      const scaleFactor = 1;
      const newMat = compose(translate(0, 0), rotate(0), scale(scaleFactor, scaleFactor));
      return newMat;
    });
  };

  // todo useProcessCanvasCommand 메소드와 refactoring 가능성 있음
  const fullScreen = () => {
    if (!document.fullscreenElement) {
      const body = document.getElementsByTagName('body')[0];
      if (body.requestFullscreen) return body.requestFullscreen();
      if (!document.fullscreenElement) {
        if (body.requestFullscreen) return body.requestFullscreen();
      } else if (document.exitFullscreen) return document.exitFullscreen();
      return Promise.resolve();
    } else {
      document.exitFullscreen();
    }
  };

  const toggleLabel = () => {
    dispatchToReducer({
      type: 'CHANGE_CFG',
      cfgChangeAction: {
        key: 'isDisplayLabels',
        value: !state?.cfg?.isDisplayLabels
      }
    });
  };

  const toggleData = () => {
    dispatchToReducer({
      type: 'CHANGE_CFG',
      cfgChangeAction: {
        key: 'isDisplayDataValues',
        value: !state?.cfg?.isDisplayDataValues
      }
    });
  };

  const lineDetect = (): void => {
    if (!!state?.detectionInfo?.detectedFileName) {
      showModal(alreadyDetectedModalOptions);
    } else if ((state?.images?.imgExtension || '').toLowerCase() !== 'svg') {
      showModal(onlySvgFileDetectableModalOptions);
    } else {
      showMPfdModal('detection');
    }
  };

  const toggleZoomScale = (): void => {
    setHasZoomScale((prevState) => !prevState);
  };

  const importFile = () => {
    setImportModalData(true);
  };

  const exportFile = () => {
    if (file.localInfo.isChanged) {
      showModal({
        title: 'Export File',
        content: 'The layout file has been changed.\nDo you want to save before exporting?',
        isShowCancel: true,
        confirmLabel: 'Save',
        onConfirm() {
          // 저장하고 next();
          showSpinner({});
          const runSave = async () => {
            const savedFile = await save(file.fileName, false);
            if (savedFile) {
              setIsShowExportModal(true);
              hideSpinner();
            }
          };
          runSave();
        }
      });
    } else {
      setIsShowExportModal(true);
    }
  };

  return {
    create: createLayout,
    change: changeLayout,
    close: closeLayout,
    save: saveLayout,
    saveAs: openSaveAsModal,
    rename: openRenameModal,
    remove: removeLayout,
    openAllowCopyModal,
    openOwnershipModal,
    openChangeAccessibilityModal,
    showGlobalSettings,
    zoomIn,
    zoomOut,
    zoomTo,
    fullScreen,
    toggleLabel,
    toggleData,
    lineDetect,
    toggleZoomScale,
    importFile,
    exportFile
  };
}

export default useMetaPfdCommand;
